import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'

import { NavigationContext } from '../controllers/NavigationController'

import ToolBar from './ToolBar'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const styles = theme => ({
  root: {
  },
  contactButton: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
  },  
  titleText: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
  },  
})

const ChatTitleBar = ({ theme, classes, business, conversation, onViewContact, actionButton }) => {

  let title = business.name
  if (conversation.data && conversation.data.parties)
    title = <>
      { conversation.data.parties.map(p => {
          if (p.agent) {
            return null
            /*
            let name = p.agent.first_name
            if (p.agent.last_name)
              name += " " + p.agent.last_name
            if (p.agent.is_service_user)
              name += "" //" (Bot)"
            else
              name += " (Agent)"
            return <Button className={classes.contactButton}>{name}</Button>
            */
          }
          else if (p.contact) {
            let name = p.contact.first_name
            if (p.contact.last_name)
              name += " " + p.contact.last_name
            if (!name || name.length <= 0)
              name = "New Contact"
              
            return (
              <Button className={classes.contactButton}
                color='primary'
                onClick={() => onViewContact(p.contact)}
                >
                {name}
              </Button>
              )
          }
        }).reduce((p, n) => {
          if (p && p.length && n)
            return [...p, <Typography component='span'>,</Typography>, n]
          else if (n)
            return [n]
          else
            return p
        }, [])
      }
      </>
  
  return (
    <ToolBar
      title={
        <>
          <Box ml={1}>
            <Typography color='' className={classes.titleText}>
              Chat with
            </Typography>
          </Box>
          <Box style={{ flexGrow: 1 }}>
            { title }
          </Box>
          <Box>
            { actionButton }
          </Box>
        </>
      }
      />
  )
}


export default withRouter(
  withStyles(styles, { withTheme: true })(
    ChatTitleBar
  )
)
