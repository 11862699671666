import React, { Component, useState, useEffect, useContext } from 'react'

import classNames from 'classnames'

import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getUser, logout, manageAccount } from '../data/reducers/user'
import { getConnectionState, WSSTATE } from '../data/reducers/conversation'
import { getMyAvailability, setMyAvailability } from '../data/reducers/queue'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import BusinessIcon from '@material-ui/icons/Business'
import SearchIcon from '@material-ui/icons/Search'

const styles = theme => ({
  root: {
//    backgroundColor: '#f8f8f8',
    width: '100%',
    height: 40,
//    borderBottom: 'solid 1px #ccc',
//    borderBottom: 'solid 1px rgb(0, 0, 0, .1)', // #aaa', // Dark
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  leftMenuBar: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  centerMenuBar: {
    flexGrow: 1,
    align: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  rightMenuBar: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  titleText: {
    fontWeight: 800,
    fontSize: theme.typography.body1.fontSize,
    color: '#fff'
  },
  businessTitle: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: theme.typography.body1.fontSize,
    color: '#000',
    color: '#fff'    
  },
  titleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 300,
    color: '#555',
    fontWeight: 400,    
    color: 'rgb(255, 255, 255, .8)',
  },
  titleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 800,
    color: '#555',
    color: '#fff'    
  },
  menuItemDefault: {
    paddingTop: '3px',
    paddingBottom: '3px',
    flexShrink: 0
  },
  menuItemSelected: {
    position: 'relative',
    flexShrink: 0
    //paddingTop: '3px',
    //borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  menuSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '1px',
    height: '100%', 
    backgroundColor: '#ccc'
  }
})

const MainBar = ({ theme, classes, location, variant, backgroundColor, user, business, connectionState, logout, manageAccount, availability, setMyAvailability}) => {
  let stateIcon

  if (connectionState == WSSTATE.Connected) {
    if (availability && availability.availability == 'available')
      stateIcon = <FiberManualRecordIcon style={{ color: 'green' }} fontSize='small'/>
    else if (availability)
      stateIcon = <FiberManualRecordIcon style={{ color: '#cd853f' }} fontSize='small'/>
    else
      // Unknown
      stateIcon = <FiberManualRecordIcon style={{ color: '#aaa' }} fontSize='small'/>
  }
  else
    stateIcon = <FiberManualRecordIcon style={{ color: '#aaa' }} fontSize='small'/>
    
  let menuList
    
  if (business.data.uid == 45114758593096)
    menuList = [
  //    { title: 'Dashboard', link_to: '/' },
      { title: 'Home ', link_to: '/' },
      { title: 'Contacts', link_to: '/contacts' },
      { title: 'Inbox', link_to: '/chat' },    
      { title: 'Products', link_to: '/catalog' },
//      { title: 'Store', link_to: '/catalog' },
      { title: 'Order', link_to: '/order' },      
      ]
  else  
    menuList = [
  //    { title: 'Dashboard', link_to: '/' },
      { title: 'Home ', link_to: '/' },
      { title: 'Contacts', link_to: '/contacts' },
      { title: 'Inbox', link_to: '/chat' },    
      { title: 'Projects', link_to: '/workflow' },
  //    { title: 'Marketing', link_to: '/campaign' },
      //    { title: 'Pages', link_to: '/pages' },    
      ]
      
/*
  menuList = [
    ...menuList,
    { title: 'Automation', link_to: '/automation' },
    { title: 'Report', link_to: '/report' },
  ]
*/

  const path = location.pathname
  let activeMenuIndex = menuList.findIndex(item => {
    if (path == item.link_to)
        return true
    if (item.link_to != "/" && path.startsWith(item.link_to))
        return true
  })
  
  if (path == '/settings' || path.startsWith('/settings'))
    activeMenuIndex = 100 // For settings

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const showMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  }

  const hideMenu = () => {
    setMenuAnchorEl(null);
  }

  let rootStyle = {}
  if (variant == 'transparent')
    rootStyle['backgroundColor'] = 'inherit'
  else if (backgroundColor)
    rootStyle['backgroundColor'] = backgroundColor

  return (
    <div className={classes.root}
      style={rootStyle}
      >
      <div className={classes.leftMenuBar}>
        <Box ml={1} mr={3}>
          <Typography color='' className={classes.titleText}>
            { process.env.REACT_APP_TITLE || "GOAPP" }
          </Typography>
        </Box>

        { menuList.map((item, index) =>
          <Box
            className={index == activeMenuIndex ? classes.menuItemSelected : classes.menuItemDefault}>

            { index == activeMenuIndex && 
              <div style={{
                height: '3px',
                position: 'absolute',
                backgroundColor: theme.palette.primary.main,
                backgroundColor: 'rgb(255, 255, 255, .6)',
                width: '100%',
                bottom: 0
              }}
              />
            }

            <Button color='inherit' 
              className={index == activeMenuIndex ? classes.titleButtonBold : classes.titleButton}
              component={Link}
              to={item.link_to}
              >
              { item.title }
            </Button>
          </Box>
        )}

      </div>
      <div className={classes.centerMenuBar}>
      </div>
      <div className={classes.rightMenuBar}>
        <Box className={activeMenuIndex == 100 ? classes.menuItemSelected :                                                 classes.menuItemDefault}>
          { activeMenuIndex == 100 && 
            <div style={{
              height: '3px',
              position: 'absolute',
              backgroundColor: theme.palette.primary.main,
              backgroundColor: 'rgb(255, 255, 255, .6)',
              width: '100%',
              bottom: 0
            }}
            />
          }
                    
          <Button className={activeMenuIndex == 100 ? classes.titleButtonBold : classes.titleButton}
              component={Link}
              to="/settings"
            >
            <BusinessIcon />
            {/*}
            &nbsp;
            { business.data.name }
            */}
          </Button>
        </Box>
        <Box>          
          <Button className={classes.titleButton}
            >
            <SearchIcon />
          </Button>
        </Box>        
        {/*
        <div className={classes.menuSeparator} />        
        */}
        <Box className={classes.menuItemDefault}>
          <Button color='primary' className={classes.titleButton}
            onClick={showMenu}
            >
            { stateIcon }
            &nbsp;{ user.userInfo.first_name } { user.userInfo.last_name }
          </Button>
        </Box>
        <Menu
          id={menuAnchorEl ? 'status-menu-popover' : undefined}
          open={Boolean(menuAnchorEl) }
          anchorEl={menuAnchorEl}
          getContentAnchorEl={null}
          onClose={hideMenu}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}        
          >
          <MenuItem onClick={() => {
              setMyAvailability("available")
              hideMenu()
            }}>
            <FiberManualRecordIcon fontSize='small' style={{ color: 'green' }} />
            &nbsp;
            Available
          </MenuItem>
          <MenuItem onClick={() => {
              setMyAvailability("not-available")
              hideMenu()
            }}>
            <FiberManualRecordIcon fontSize='small' style={{ color: '#cd853f' }} />
            &nbsp;
            Not Available
          </MenuItem>
          <Divider/>
          <MenuItem onClick={ manageAccount }>
            <ListItemText primary="My Account" />
          </MenuItem>
          <MenuItem onClick={ logout }>
            <ListItemText primary="Log Out" />
          </MenuItem>
          </Menu>
        </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: getUser(state),
    business: getBusinessInfo(state, '0'),
    connectionState: getConnectionState(state),
    availability: getMyAvailability(state, "0"),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let callbackUrl = window.location.href
  
  return {
    logout: () => { dispatch(logout(callbackUrl)) },
    manageAccount: () => { dispatch(manageAccount(callbackUrl)) },    
    setMyAvailability: (availability) => { dispatch(setMyAvailability("0", availability))}
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MainBar)
  )
)
