import React, { Component, useState, useEffect, useContext } from 'react'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CircularProgress from '@material-ui/core/CircularProgress'
import { default as MUITable } from '@material-ui/core/Table'
import { default as MUITableBody } from '@material-ui/core/TableBody'
import { default as MUITableCell } from '@material-ui/core/TableCell'
import { default as MUITableHead } from '@material-ui/core/TableHead'
import { default as MUITableRow } from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  root: {
  },
  container: {
  },
  tableCellHeader: {
    verticalAlign: 'bottom',    
    fontSize: theme.typography.body1.fontSize,
    color: '#999',
    fontWeight: 500
  },
  tableCell: {
    verticalAlign: 'top',
    borderBottom: 'none'
  },
  titleText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700    
  },
  primaryText: {
    fontSize: theme.typography.body1.fontSize,
    color: '#333'
  },
  secondaryText: {
    fontSize: theme.typography.body2.fontSize,
    color: '#888'
  }  
})

const IDNumberFormatter = Intl.NumberFormat('ID', {
  style: 'currency',
  currency: 'IDR',
})

export const formatValue = (value, type, format) => {  
  if (value === null || value === undefined || value === "")
    return ""

  if (type == 'currency') {
    return IDNumberFormatter.format(
       Number.parseFloat(value).toFixed(0)
      ).replace(",00", "") 
  }
  else if (type == 'number')
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return value
}

export const TableDataCell = ({ theme, classes, column, header, style, value, secondaryValue, onClick }) => {
  let align = 'left'
  if (column.type === 'number' || column.type == 'currency' || column.type == 'decimal')
    align = 'right'
  let customStyle = style || {}
  
  let valueComponent
  if (header)
    valueComponent = value
  else if (value === null || value === undefined || value === "")
    valueComponent = null
  else if (typeof value === 'function')
    valueComponent = value
  else if (React.isValidElement(value))
    valueComponent = value
  else if (column.type == 'image')
    valueComponent = (
      <img src={value} style={{
          maxHeight: '3rem',
          maxWidth: '20rem'
        }} />
      )
  else {
    valueComponent = formatValue(value, column.type)
    if (column.variant == 'title')
      valueComponent = (
        <span className={classes.titleText}>
          { valueComponent }
        </span>
        )
    else
      valueComponent = (
        <span className={classes.primaryText}>
          { valueComponent }
        </span>
        )        
  }
  
  if (header)
    return (
      <MUITableCell className={header ? classes.tableCellHeader : classes.tableCell} key={column.key} align={align} style={customStyle}
        >
        { valueComponent }
      </MUITableCell>
    )
        
  return (
    <MUITableCell className={header ? classes.tableCellHeader : classes.tableCell} key={column.key} align={align} style={customStyle}
      onClick={onClick}
      >
      <span className={classes.primaryText}>
        { valueComponent }
      </span>
      { secondaryValue && 
        <>
        <br/>
        <span className={classes.secondaryText}>
          { secondaryValue }
        </span>
        </>
      }
    </MUITableCell>
  )
}

const TableDataHeader = (props) => {
  let style = {
    backgroundColor: 'transparent'
  }
  
  return (
    <TableDataCell header={true} style={style} value={props.column.label} {...props} />
  )
}

const TableData = ({ theme, classes, columns, rows, isFetching, hasMore, onRowClick, ...props }) => {
  
  const getCellValue = (row, column) => {
    if (props.getCellValue)
      return props.getCellValue(row, column)
    return row[column.key]
  }
  
  const getCellSecondaryValue = (row, column) => {
    if (props.getCellSecondaryValue)
      return props.getCellSecondaryValue(row, column)
    return null
  }
  
  return (
    <div className={classes.root}>
      <MUITable stickyHeader>
        <MUITableHead>
          <MUITableRow>          
            {columns.map((column) => (
              <TableDataHeader theme={theme} classes={classes} column={column} />
            ))}
          </MUITableRow>
        </MUITableHead>
        <MUITableBody>              
          {rows && rows.map((row) => {
            return (
              <MUITableRow hover tabIndex={-1} key={row.code}
                >
                {columns.map((column) => (
                  <TableDataCell theme={theme} classes={classes} 
                    column={column} 
                    value={getCellValue(row, column)} 
                    secondaryValue={getCellSecondaryValue(row, column)}
                    onClick={() => onRowClick(row)}
                    />
                ))}
              </MUITableRow>
            );
          })}
        </MUITableBody>
      </MUITable>
      
      { isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
            <CircularProgress size='1.5rem' disableShrink={true} />        
        </Box>
      }      
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(
  TableData
  )