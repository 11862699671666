import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getConversation, fetchConversation } from '../data/reducers/conversation'

import { withStyles } from '@material-ui/core/styles'

import ChatView from './chat/ChatView'

const styles = theme => ({
  root: {
  },
})

const Chat = ({ theme, classes, conversationKey, onLoadConversation, conversation, ...props }) => {

  useEffect(() => {
    if (conversation &&  conversation.lastFetched && onLoadConversation)
      onLoadConversation(conversation)
  }, [conversation && conversation.lastFetched])

  return (
    <ChatView 
      conversationKey={conversationKey}
      {...props} 
      />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    conversation: getConversation(state, ownProps.conversationKey)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChatView)
)