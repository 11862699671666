import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getContactRecord, fetchContactActivityHistory } from '../data/reducers/contact'

import { NavigationContext } from '../controllers/NavigationController'
import { withStyles } from '@material-ui/core/styles'

import ToolBar from './ToolBar'
import SplitView from './SplitView'
import ContactSideView from './ContactSideView'

import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import ChatIcon from '@material-ui/icons/Chat'
import DevicesIcon from '@material-ui/icons/Devices'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ActivityIcon from '@material-ui/icons/DirectionsRun'
import Paper from '@material-ui/core/Paper'

const activityTypeIcon = {
  'chat-with-operator': ChatIcon,
  'chat-with-agent': ChatIcon,
  'app-session': DevicesIcon,
  'order-placed': ReceiptIcon
}

const styles = theme => ({
  root: {
  },
  activityList: {
    backgroundColor: '#f6f6f6'
  },
})

const formatDuration = (seconds) => {
  if (seconds < 0)
    return '0m'
  let hour = parseInt(seconds/3600)
  let minute = parseInt((seconds % 3600) / 60)
  if (hour > 0)
    return `${hour}h ${minute}m`
  else
    return `${minute}m`
}

const ActivityItem = ({ theme, classes, activity, isFirst, isLast }) => {

  let infoList = []

  infoList.push(
    <Typography variant='body2' component='span' style={{
        color: 'grey',
        fontSize: '.8rem'
      }}>
      { moment(activity.activity_time).fromNow(false) }
    </Typography>
  )

  if (activity.city || activity.country_name)
    infoList.push(
      <Typography variant='body2' component='span' style={{
        color: 'grey',
        fontSize: '.8rem'
        }}>
        { activity.city }, { activity.country_name }
      </Typography>
    )

  if (activity.app)
    infoList.push(
      <Typography variant='body2' component='span' style={{
        color: 'grey',
        fontSize: '.8rem'
        }}>
        { activity.app.name }
      </Typography>
    )

  return (
    <div style={{
        display: 'flex',
        padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(3)}px`,
//        alignItems: 'center'
      }}>
      <div style={{
          width: '2rem',
          marginTop: 0,
          marginRight: theme.spacing(2),
          position: 'relative',
          flexShrink: 0
        }}>

        { !isFirst &&
        <div style={{
            width: '2pt',
            height: '50%',
            backgroundColor: '#eaeaea',
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          />
        }

        { !isLast &&
        <div style={{
            width: '2pt',
            height: '50%',
            backgroundColor: '#eaeaea',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          />
        }

        <div style={{
            width: '28pt',
            height: '28pt',
            borderRadius: '14pt',
            border: 'solid 1px #eaeaea',
            backgroundColor: '#ffffff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          { React.createElement(
              activityTypeIcon[activity.activity_type.code] || ActivityIcon, {
                style: { color: '#aaa' },
                fontSize: 'small'
              }
          )}
        </div>
      </div>
      <div style={{
          flexGrow: 1,
          padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
          margin: `${theme.spacing(1)}px 0`,
          backgroundColor: 'white',
          borderRadius: '2pt',
          border: 'solid 1px #eaeaea'
        }}>
        <Typography variant='body2' style={{
            fontWeight: 600
          }}>
          { activity.activity_type.name }
          { activity.duration > 0 &&
            <span style={{ fontWeight: 400 }}>
              &nbsp; ({formatDuration(activity.duration)})
            </span>
          }
        </Typography>
        <div>
          { infoList.length > 0 &&
            <div style={{ color: theme.mutedColor }}>
              { infoList.reduce((p, n) => p ?
                [p, <span> · </span>, n]
                : [n]
                , null)
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const ActivityHistory = ({ theme, classes, activityHistory }) => {

  const activityList = activityHistory ? activityHistory.data : []

  return (
    <div className={classes.activityList}>
      <div style={{
          padding: theme.spacing(2),
          display: 'flex',
          alignItems: 'center'
        }}>
        <Box flexGrow={1}>
          <Typography variant='body1'>
            Latest Activities
          </Typography>
        </Box>
      </div>

      <div>
      { activityHistory && activityHistory.isFetching &&
        <div style={{ padding: theme.spacing(2), display: 'flex', justifyContent: 'center'}}>
          <CircularProgress size='1.5rem' disableShrink={true} />
        </div>
      }
      { activityList.map((a, index) =>
        <ActivityItem
          theme={theme}
          classes={classes}
          activity={a}
          isFirst={index == 0}
          isLast={index == activityList.length - 1}
        />
      )}
      </div>
    </div>
  )
}

const ContactView = ({ theme, classes, contactKey, contact, fetchContactActivityHistory }) => {

  const navigationContext = useContext(NavigationContext)

  useEffect(() => {
    fetchContactActivityHistory()
  }, [contactKey])

  return (
    <div className={classes.root}>
      <SplitView
        topInset={navigationContext.contentInset.top + 44}
        leftPaneContent={
          <ContactSideView
            contactKey={contactKey}
            />
        }
        >
        <ActivityHistory
          theme={theme}
          classes={classes}
          activityHistory={contact && contact.activityHistory}
        />
      </SplitView>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    contact: getContactRecord(state, ownProps.contactKey),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContactActivityHistory: () => { dispatch(fetchContactActivityHistory(ownProps.contactKey)) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactView)
)