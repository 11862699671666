import { updateObject, updateItemInArray, createReducer } from './utility'
import ls from 'local-storage'
// Initial Data

const userInitial = {
  isAuthenticating: false,
  isLoggedIn: false,
  authError: null,
  authToken: null,
  userInfo: null,
}

// Actions

const ACTION = {
  authenticateWithToken: "authenticateWithToken",
  completeLogout: "completeLogout",
  authenticating: "authenticating",  
  receiveTokenInfo: "receiveAuthInfo",  
  errorAuthenticating: "errorAuthenticating",
}


export const getUser = (state) => {
  return state.service.user
}

// Async Action Creators

export const login = (callbackUrl, removeUserInfo=false, register=false) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (user.isLoggedIn || user.isAuthenticating)
      return Promise.resolve()
      
    // Open login page
    let url = process.env.REACT_APP_ACCOUNT_URL + "/account/authorize/"
    let params = { callback: callbackUrl }
    // Login as staff
    params["staff"] = 1
    
    // Try to login with previous identity
    if (user.userInfo && 'uid' in user.userInfo) {
      params['uid'] = user.userInfo.uid
      
      if (removeUserInfo)
        // Remove userInfo from local storage, so that we don't try to login
        // if failed
        ls.remove('_userInfo')
    }
    
    if (register)
      params['register'] = 1
      
//    alert(JSON.stringify(params, null, 2))
      
//    let params = { callback: process.env.REACT_APP_PUBLIC_URL + "/auth_callback" }
//    let params = { callback: "http://178.128.86.10:9000/auth_callback/" }
    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")
      
    // Redirect to url
    window.open(url, '_self')
  }
}

export const register = (callbackUrl, removeUserInfo=false) => {
  return login(callbackUrl, removeUserInfo, true)
}


export const logout = (callbackUrl) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (!user.isLoggedIn)
      return Promise.resolve()

    // Remove _userInfo to prevent auto login
    ls.remove('_userInfo')

    // Logout from authentication service.
    let url = process.env.REACT_APP_ACCOUNT_URL + "/account/logout/"
    let params = { callback: callbackUrl, business: getState().service.business[0].data.uid }
    
    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")
      
    // Redirect to url
    window.open(url, '_self')    
    
//    // Reset user to initial
//    dispatch(completeLogout())
  }
}

export const manageAccount = (callbackUrl) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (!user.isLoggedIn)
      return Promise.resolve()
      
    // Open manage account page
    let url = process.env.REACT_APP_ACCOUNT_URL + "/account/manage/"
    let params = { callback: callbackUrl }
    params['business'] = getState().service.business[0].data.uid
    params['uid'] = user.userInfo.uid
    
    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")    
    
    // Redirect to url
    window.open(url, '_self')
  }
}

export const authenticateWithSSOToken = (sso_token) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (user.isAuthenticating || user.authError)
      return Promise.resolve()
  
    let url = process.env.REACT_APP_ACCOUNT_URL + "/auth/token-sso-auth/"
    
    let data = {
      sso_token
    }
    
    dispatch(authenticating())
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(response => {
        response.json().then(json => {
          if (response.ok)
            dispatch(authenticateWithToken(json.token, true))
          else
            dispatch(errorAuthenticating(json))          
        })
        .catch(error => dispatch(errorAuthenticating(error)))
      })
      .catch(error => dispatch(errorAuthenticating(error)))
  }
}

export const authenticateWithToken = (authToken, resume=false) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if ((user.isAuthenticating && !resume) || user.authError)
      return Promise.resolve()
  
    let url = process.env.REACT_APP_ACCOUNT_URL + "/auth/token-info/"
    
    let data = {
      token: authToken
    }
    
//    alert(url)
    
    dispatch(authenticating())
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTokenInfo(authToken, json))
//        dispatch(fetchCart(authToken))
      })
      .catch(error => dispatch(errorAuthenticating(error)))
  }
}

// Synchronous Action Creators

export const authenticating = () => ({
  type: ACTION.authenticating,
});

export const completeLogout = () => ({
  type: ACTION.completeLogout,
});

export const receiveTokenInfo = (authToken, tokenInfo) => ({
  type: ACTION.receiveTokenInfo,
  authToken,
  tokenInfo
});

export const errorAuthenticating = (error) => ({
  type: ACTION.errorAuthenticating,
  error
});

// User reducer

function _authenticating(state, action) {
  return updateObject(state, { isAuthenticating: true })
}

function _completeLogout(state, action) {
  ls.remove('_userInfo')

  return updateObject(state, {
    isLoggedIn: false,
    authToken: null,
    tokenInfo: {}
  })
}

function _errorAuthenticating(state, action) {  
  return {
    ...state,
    isAuthenticating: false,
    authError: action.error
  }
}

function _receiveTokenInfo(state, action) {
  let userInfo = action.tokenInfo
  
//  alert(JSON.stringify(userInfo, null, 2))
  
  // Store userInfo on local storage
  ls.set('_userInfo', userInfo)

  return {
    ...state,
    isAuthenticating: false,
    isLoggedIn: true,
    authToken: action.authToken,
    userInfo: userInfo,
    isStaff: userInfo.is_staff
  }
}

const userReducer = (state=null, action) => {
  if (state == null) {
    // Get userInitial from local storage, but we're still in
    // not authenticated state, authToken is still null.
    // We'll use the info to auto authenticate to auth service
    state = userInitial
    let userInfo = ls.get('_userInfo')
    if (userInfo && userInfo !== undefined)
      state.userInfo = userInfo
  }
  
  const handlers = {
    [ACTION.authenticating]: _authenticating,
    [ACTION.errorAuthenticating]: _errorAuthenticating,
    [ACTION.receiveTokenInfo]: _receiveTokenInfo,
    [ACTION.completeLogout]: _completeLogout,
  }
  
  if (handlers.hasOwnProperty(action.type))
    return handlers[action.type](state, action)
  else
    return state
}

export default userReducer