import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSetting, updateSetting, fetchSetting, callCustomAPI } from '../data/reducers/setting'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { SectionListView, MoreSectionItem, SectionItem, SectionCard } from './SettingView'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  root: {
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})

const ScriptForm = ({ theme, classes, operator, open, onClose, callCustomAPI }) => {
  
  const [scriptQuery, setScriptQuery] = useState({ 
    isFetching: false, isUpdating: false, lastFetched: null, script: ""
  })
  
  const [newScript, setNewScript] = useState(null)
  
  const onScriptChange = (value) => {
    setNewScript(value)
  }
  
  const isChanged = Boolean(newScript)
  
  const handleClose = () => {
    if (isChanged && window.confirm("Discard change?"))
      return
      
    onClose()
  }

  const downloadScript = () => {
    callCustomAPI((apiUrl, authHeaders) => {
      const url = apiUrl + `/operator/service/operator/${operator.uid}/download_script/`
      setScriptQuery({...scriptQuery, isFetching: true})
      return fetch(url, { 
          headers: authHeaders  
        })
        .then(response => response.json())
        .then(json => setScriptQuery({
          ...scriptQuery,
          isFetching: false,
          lastFetched: Date.now(),
          script: JSON.stringify(json, null, 2)
        }))
        .catch(error => {
          alert("Download script failed! Error: " + error)
          onClose()
        })
      })    
  }

  const uploadScript = () => {
    callCustomAPI((apiUrl, authHeaders) => {
      const url = apiUrl + `/operator/service/operator/${operator.uid}/upload_script/`
      setScriptQuery({...scriptQuery, isUpdating: true})
      return fetch(url, {
          method: 'PUT',
          cache: 'no-cache',
          headers: {
            ...authHeaders,
            'Content-Type': 'application/json',
          },
          body: newScript
        })
        .then(response => {
          if (response.ok) {
            setScriptQuery({
              ...scriptQuery,
              isUpdating: false,
              lastFetched: Date.now(),
              script: newScript
            })
            setNewScript(null)
          }
          else {
            response.text().then(text => {
              alert("Upload script failed! Error: " + text)
              setScriptQuery({...scriptQuery, isUpdating: false})              
            })
          }
        })
        .catch(error => {
          alert("Upload script failed! Error: " + error)
          setScriptQuery({...scriptQuery, isUpdating: false})
        })
      })    
  }
  
  useEffect(() => {
    downloadScript()    
  }, [])
  
  const onSave = () => {
/*
    // Ensure script is a valid JSON
    try {
      JSON.parse(newScript)
    } catch (e) {
      alert("Not a valid JSON! Message: " + e)
      return
    }
*/
    
    uploadScript()
  }
      
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
      <DialogTitle>Operator Script</DialogTitle>
      <DialogContent>
        { scriptQuery.isFetching &&
          <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
            <CircularProgress size='1.5rem' disableShrink={true} />        
          </Box>
        }
        
        { scriptQuery.lastFetched &&        
          <Box display='flex' flexDirection='column' alignItems='stretched'>
            <TextField
              multiline
              value={newScript || scriptQuery.script}
              onChange={e => onScriptChange(e.target.value)}
              variant='filled'
              disabled={scriptQuery.isUpdating}
              />
          </Box>      
        }
      </DialogContent>
      <DialogActions>
        { scriptQuery.lastFetched &&
          <Button onClick={onSave} color="primary" variant='contained'
            disabled={!isChanged || scriptQuery.isUpdating}
            >
            Save
          </Button>      
        }
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>        
  )
}

const OperatorSettingCard = ({ theme, classes, data, updateSetting, callCustomAPI }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)
  
  const [openScriptForm, setOpenScriptForm] = useState(false)  
  
  const scriptFieldValue = (item, value) => {
    return (
      <Button variant='outlined' onClick={() => setOpenScriptForm(true)}>
        Show
      </Button>
    )
  }  
  
  const fields = [
    { fieldName: 'name', type: 'text', label: 'Name' },
    { fieldName: 'enabled', type: 'switch', label: 'Enabled' },
    { fieldName: 'script', type: 'text', label: 'Script', renderValue: scriptFieldValue }
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])

  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting(editData)
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }  
  
  return (
    <SectionCard theme={theme} classes={classes}
      title={data.name}
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
      { openScriptForm &&
        <ScriptForm theme={theme} classes={classes} 
          operator={data} 
          open={true} 
          onClose={() => setOpenScriptForm(false)} 
          callCustomAPI={callCustomAPI}
          />
      }      
    </SectionCard>
  )
}

const OperatorsSettingView = ({ theme, classes, fetchOperatorsSetting, operatorsSetting, updateOperatorsSetting, callCustomAPI }) => {

  useEffect(() => {
    fetchOperatorsSetting()
  }, [])

  if (!operatorsSetting.lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        { operatorsSetting.isFetching &&
          <CircularProgress size='1.5rem' disableShrink={true} />
        }
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <SectionListView title="Operator">        
        { operatorsSetting.data.map(operator =>
          <OperatorSettingCard theme={theme} classes={classes} data={operator}
            updateSetting={data => updateOperatorsSetting({uid: operator.uid, ...data})}
            callCustomAPI={callCustomAPI}
          />
        )}
      </SectionListView>
    </div>    
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    operatorsSetting: getSetting(state, 'operators'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchOperatorsSetting: () => { dispatch(fetchSetting('operators', true)) },
    updateOperatorsSetting: (data) => { dispatch(updateSetting('operators', data)) },
    callCustomAPI: (apiFunc) => { dispatch(callCustomAPI(apiFunc)) }
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OperatorsSettingView)
)