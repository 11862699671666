import ls from 'local-storage'
import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const contactRecordInitial = {
  key: null,
  isFetching: false,
  isUpdating: false,
  error: null,
  lastFetched: null,
  data: { status: null },
  visitHistory: {
    isFetching: false,
    error: null,
    lastFetched: null,
    count: 0,
    data: []
  },
  activityHistory: {
    isFetching: false,
    error: null,
    lastFetched: null,
    count: 0,
    data: []
  }
}

const contactListInitial = {
  isFetching: false,
  error: null,
  lastFetched: null,
  data: {
    count: 0,
    records: [ ],
  }
}

const contactFieldsInitial = {
  isFetching: false,
  error: null,
  lastFetched: null,
  data: []
}

// Actions
const ACTION = {
  requestContactList: "requestContactList",
  receiveContactList: "receiveContactList",
  errorRequestContactList: "errorRequestContactList",
  requestContactRecord: "requestContactRecord",
  receiveContactRecord: "receiveContactRecord",
  errorRequestContactRecord: "errorRequestContactRecord",
  updatingContactRecord: "updatingContact",
  errorUpdatingContactRecord: "errorUpdatingContact",

  requestContactVisitHistory: "requestContactVisitHistory",
  receiveContactVisitHistory: "receiveContactVisitHistory",
  errorRequestContactVisitHistory: "errorRequestContactVisitHistory",

  requestContactActivityHistory: "requestContactActivityHistory",
  receiveContactActivityHistory: "receiveContactActivityHistory",
  errorRequestContactActivityHistory: "errorRequestContactActivityHistory",

  requestContactFields: "requestContactFields",
  receiveContactFields: "receiveContactFields",
  errorRequestContactFields: "errorRequestContactFields",
}

export const getContactList = (state) => {
  return state.service.contact.list
}
export const getContactFields = (state) => {
  return state.service.contact.fields
}

export const getContactRecord = (state, key) => {
  if (key in state.service.contact.records)
    return state.service.contact.records[key]
  return null
}

// Async action creator

export const fetchContactList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/service/contact/"

//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
      
    let service_key = user.userInfo.business_uid      

    dispatch(requestContactList())
    
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContactList(json)))
      .catch(error => dispatch(errorRequestContactList(error)))
  }
}

export const fetchContactRecord = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactRecord(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/service/contact/" + key + "/"
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestContactRecord(key)); 
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => {
          dispatch(receiveContactRecord(key, json))
          dispatch(fetchContactVisitHistory(key, true))
      })
      .catch(error => dispatch(errorRequestContactRecord(key, error)));
  }
}

export const fetchContactVisitHistory = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactRecord(getState(), key)
    if (res && (res.visitHistory.isFetching || (res.visitHistory.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/service/contact/" + key + "/visit_history/"
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch({
      type: ACTION.requestContactVisitHistory,
      key
    })

    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContactVisitHistory(key, json)))
      .catch(error => dispatch({
        type: ACTION.errorRequestContactVisitHistory,
        key,
        error
      }))
  }
}

export const fetchContactActivityHistory = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactRecord(getState(), key)
    if (res && (res.activityHistory.isFetching || (res.activityHistory.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/service/contact/" + key + "/activity_history/"
    //alert("Fetching: " + url)

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch({
      type: ACTION.requestContactActivityHistory,
      key
    })

    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContactActivityHistory(key, json)))
      .catch(error => dispatch({
        type: ACTION.errorRequestContactActivityHistory,
        key,
        error
      }))
  }
}

export const updateContactRecord = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + `/contact/service/contact/${key}/`
    
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    let service_key = user.userInfo.business_uid
      
    dispatch({
      type: ACTION.updatingContactRecord,
      key
    })

    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        return response.json().then(json => {
          if (response.ok)
            dispatch(receiveContactRecord(key, json))
          else
            dispatch({
              type: ACTION.errorUpdatingContactRecord,
              key,
              error: JSON.stringify(json, null, 2)
            })
           if (onComplete)
             onComplete()
        })
      })
      .catch(error => dispatch({
        type: ACTION.errorUpdatingContactRecord,
        key,
        error
      }))
  }
}

export const fetchContactFields = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getContactFields(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/contact/service/contact/contact_fields/"

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
      
    let service_key = user.userInfo.business_uid      

    dispatch({
      type: ACTION.requestContactFields
    })
    
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveContactFields(json)))
      .catch(error => dispatch({
        type: ACTION.errorRequestContactFields,
        error
      }))
  }
}

// Synchronous Action Creators

export const requestContactList = () => ({
  type: ACTION.requestContactList,
})

export const receiveContactList = (json) => {
  let result = {
    count: json.count,
    offset: json.offset,
    size: json.size,
    records: json.records
  }

  return {
    type: ACTION.receiveContactList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestContactList = (error) => ({
  type: ACTION.errorRequestContactList,
  error
})

export const requestContactRecord = (key) => ({
  type: ACTION.requestContactRecord,
  key
})

export const receiveContactRecord = (key, json) => {
  let result = json
  
//  alert("Contact Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveContactRecord,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const receiveContactVisitHistory = (key, json) => {
  let result = json
  
  return {
    type: ACTION.receiveContactVisitHistory,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const receiveContactActivityHistory = (key, json) => {
  let result = json
  
  return {
    type: ACTION.receiveContactActivityHistory,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestContactRecord = (key, error) => ({
  type: ACTION.errorRequestContactRecord,
  key,
  error
})

export const receiveContactFields = (json) => {
//  alert("contactFields: " + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveContactFields,
    result: json,
    receivedAt: Date.now()
  };
}

// Reducers for Conversation

const contactRecordReducer = createReducer(contactRecordInitial, {
  [ACTION.requestContactRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveContactRecord]: (state, action) => {
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        lastFetched: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.requestContactVisitHistory]: (state, action) => {  
      return {
        ...state,
        visitHistory: {
          ...state.visitHistory,
          isFetching: true,
        }
      }
    },
  [ACTION.receiveContactVisitHistory]: (state, action) => {
      return {
        ...state,
        visitHistory: {
          ...state.visitHistory,
          isFetching: false,
          lastFetched: action.receivedAt,
          data: action.result
        }
      }
    },
  [ACTION.requestContactActivityHistory]: (state, action) => {  
      return {
        ...state,
        activityHistory: {
          ...state.activityHistory,
          isFetching: true,
        }
      }
    },
  [ACTION.receiveContactActivityHistory]: (state, action) => {
      return {
        ...state,
        activityHistory: {
          ...state.activityHistory,
          isFetching: false,
          lastFetched: action.receivedAt,
          data: action.result
        }
      }
    },    
  [ACTION.errorRequestContactRecord]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    },
  [ACTION.errorRequestContactVisitHistory]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        visitHistory: {
          ...state.visitHistory,
          isFetching: false,
          error: action.error
        }
      }
    },        
  [ACTION.errorRequestContactActivityHistory]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        activityHistory: {
          ...state.activityHistory,
          isFetching: false,
          error: action.error
        }
      }
    },        
  [ACTION.updatingContactRecord]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.errorUpdatingContactRecord]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        error: action.error
      }
    },
})

const contactListReducer = createReducer(contactListInitial, {
  [ACTION.requestContactList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveContactList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestContactList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    },
})

const contactFieldsReducer = createReducer(contactFieldsInitial, {
  [ACTION.requestContactFields]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveContactFields]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestContactFields]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    },
})

function contactRecordByKeyUpdater(state, action) {
  let key = action.key
  return {
    ...state,
    [key]: contactRecordReducer(state[key], action)
  }
}

const contactRecordByKeyReducer = createReducer({}, {
  [ACTION.requestContactRecord]: contactRecordByKeyUpdater,
  [ACTION.requestContactVisitHistory]: contactRecordByKeyUpdater,
  [ACTION.requestContactActivityHistory]: contactRecordByKeyUpdater,
  [ACTION.updatingContactRecord]: contactRecordByKeyUpdater,
  [ACTION.receiveContactRecord]: contactRecordByKeyUpdater,
  [ACTION.receiveContactVisitHistory]: contactRecordByKeyUpdater,
  [ACTION.receiveContactActivityHistory]: contactRecordByKeyUpdater,
  [ACTION.errorRequestContactRecord]: contactRecordByKeyUpdater,
  [ACTION.errorUpdatingContactRecord]: contactRecordByKeyUpdater,
  [ACTION.errorRequestContactVisitHistory]: contactRecordByKeyUpdater,
  [ACTION.errorRequestContactActivityHistory]: contactRecordByKeyUpdater,
})

// Combine list and records reducer

const contactReducer = combineReducers({
  list: contactListReducer,
  records: contactRecordByKeyReducer,
  fields: contactFieldsReducer,
});

export default contactReducer