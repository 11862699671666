import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const productRecordInitial = {
  isFetching: false,
  isUpdating: false,
  lastError: null,
  lastFetched: null,  
  data: { }
}

const productListInitial = {
  isFetching: false,
  lastError: null,
  lastFetched: null,
  data: { records: null },
  filter: { },
  sort_by: [ ]
}

const productFieldsInitial = {
  isFetching: false,
  lastError: null,
  lastFetched: null,
  data: []
}

// Actions
const ACTION = {
  requestProductRecord: "requestProductRecord",
  receiveProductRecord: "receiveProductRecord",
  errorRequestProductRecord: "errorRequestProductRecord",
  modifyProductRecord: "modifyProductRecord",
  errorModifyProductRecord: "errorModifyProductRecord",
}

const LIST_ACTION = {
  requestProductList: "requestProductList",
  receiveProductList: "receiveProductList",
  errorRequestProductList: "errorRequestProductList",
}

const PRODUCTFIELDS_ACTION = {
  requestProductFields: "requestProductFields",
  receiveProductFields: "receiveProductFields",
  errorRequestProductFields: "errorRequestProductFields",  
}

export const getProductList = (state) => {
  return state.service.product.productList
}


export const getProduct = (state, key) => {
  return state.service.product.productRecords.key || productRecordInitial
}

export const getProductFields = (state) => {
  return state.service.product.productFields
}
// Async action creator

export const fetchProductList = (filter, sort_by, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProductList(getState())
    if (JSON.stringify(filter || {}) != JSON.stringify(res.filter || {}) ||
        JSON.stringify(sort_by || []) != JSON.stringify(res.sort_by || []))
        res = productListInitial
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL +"/catalog/service/product/?"
    if (filter && Object.keys(filter).length > 0)
      url += Object.keys(filter).map(key => (
          `${key}=${encodeURIComponent(filter[key])}`)
        ).join("&") + "&"
    if (sort_by)
      url += "sort_by=" + sort_by.join(",")
    if (url.endsWith("?") || url.endsWith("&"))
      url = url.substring(0, url.length-1)
      
//    alert("Fetching: " + url)

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestProductList(filter, sort_by)); 
    return fetch(url, { 
              headers: { 'X-Service-Key': service_key, ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductList(filter, sort_by, json)))
      .catch(error => dispatch(errorRequestProductList(filter, sort_by, error)));
  }
}

export const fetchProduct = (key, params, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProduct(getState(), key)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/catalog/service/product/" + key + "/"
    
    if (params)
      url += Object.keys(params).map(key => (
        "&" + key + "=" + params[key])).join("")

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }    
    let service_key = user.userInfo.business_uid
        
    dispatch(requestProductRecord(key)); 
    return fetch(url, { 
              headers: { 'X-Service-Key': service_key, ...authorization }            
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductRecord(key, json)))
      .catch(error => dispatch(errorRequestProductRecord(key, error)))
  }
};

export const updateProduct = (key, data) => {
  return (dispatch, getState) => {

    let params = null

    let url = process.env.REACT_APP_GOAPP_API_URL + "/catalog/service/product/" + key + "/"
    let api_key = process.env.REACT_APP_GOAPP_API_KEY

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }    
    let service_key = user.userInfo.business_uid
    
    dispatch(modifyProductRecord(key, data)); 
    return fetch(url, { 
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'X-Service-Key': service_key, 
          'Content-Type': 'application/json',
          ...authorization
        },
        body: JSON.stringify(data)     
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductRecord(key, json)))
      .catch(error => dispatch(errorModifyProductRecord(key, error)))
  }
}

export const fetchProductFields = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getProductFields(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/catalog/service/product/product_fields/"

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
      
    let service_key = user.userInfo.business_uid      

    dispatch({
      type: PRODUCTFIELDS_ACTION.requestProductFields
    })
    
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductFields(json)))
      .catch(error => dispatch({
        type: PRODUCTFIELDS_ACTION.errorRequestProductFields,
        error
      }))
  }
}

// Synchronous Action Creators

export const requestProductList = (filter, sort_by) => ({
  type: LIST_ACTION.requestProductList,
  filter,
  sort_by
});

export const receiveProductList = (filter, sort_by, json) => {

  let result = {
    count: json.length,
    records: json
  }
//  alert("receiveProductList")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: LIST_ACTION.receiveProductList,
    filter,
    sort_by,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProductList = (filter, sort_by, error) => ({
  type: LIST_ACTION.errorRequestProductList,
  filter,
  sort_by,
  error
});

export const modifyProductRecord = (key) => ({
  type: ACTION.modifyProductRecord,
  key
})

export const requestProductRecord = (key) => ({
  type: ACTION.requestProductRecord,
  key
});

export const receiveProductRecord = (key, json) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProductRecord,
    key,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProductRecord = (key, error) => ({
  type: ACTION.errorRequestProductRecord,
  key,
  error
});

export const errorModifyProductRecord = (key, error) => ({
  type: ACTION.errorModifyProductRecord,
  key,
  error
})

export const receiveProductFields = (json) => {
  return {
    type: PRODUCTFIELDS_ACTION.receiveProductFields,
    result: json,
    receivedAt: Date.now()
  };
}

// Reducers for Product List

const productListReducer = createReducer(productListInitial, {
  [LIST_ACTION.requestProductList]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [LIST_ACTION.receiveProductList]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [LIST_ACTION.errorRequestProductList]: (state, action) => {
      alert("Request product list failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    }
})

// Reducers for ProductRecord

const productRecordReducer = createReducer(productRecordInitial, {
  [ACTION.requestProductRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyProductRecord]: (state, action) => {
    return {
      ...state,
      isUpdating: true
    }
  },
  [ACTION.receiveProductRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProductRecord]: (state, action) => {
      alert("Request content record failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    },
  [ACTION.errorModifyProductRecord]: (state, action) => {
      alert("Request content record failed: " + action.error)
      return {
        ...state,
        isUpdating: false,
        lastError: action.error
      }
    }
})

const productRecordByKeyReducer = (state={}, action) => {
  if (action.type in ACTION) {
    const key = action.key
    return {
      ...state,
      [key]: productRecordReducer(state[key], action)
    }
  }
  
  return state
}


const productFieldsReducer = createReducer(productFieldsInitial, {
  [PRODUCTFIELDS_ACTION.requestProductFields]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [PRODUCTFIELDS_ACTION.receiveProductFields]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [PRODUCTFIELDS_ACTION.errorRequestProductFields]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    },
})

// Combine all reducer

const productReducer = combineReducers({
  productList: productListReducer,
  productRecords: productRecordByKeyReducer,
  productFields: productFieldsReducer,
})

export default productReducer
