import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getUser } from '../data/reducers/user'
import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getProductList, fetchProductList } from '../data/reducers/product'

import { NavigationContext } from './NavigationController'

import ProductListControler from './ProductListController'

import FilterBar from '../views/FilterBar'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'

import TableData, { formatValue } from '../views/TableData.js'

import ProductIcon from '@material-ui/icons/LocalOffer'
import CollectionIcon from '@material-ui/icons/Folder'
import DiscountIcon from '@material-ui/icons/MoneyOff'

const styles = theme => ({
  root: {
  },
  sectionTitle: {
    fontWeight: 700
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  cardView: {
    // Larger bottom to accomodate shadow
    paddingBottom: theme.spacing(8)
  },
  card: {
  },
  cardTitle: {
    fontWeight: 500,
  },
  counterText: {
    color: 'black',
    fontWeight: 500
  },
  tableView: {

  },
  calendarView: {
  },

})

const TableView = ({ theme, classes, records, lastFetched, isFetching, onEditContent, onClick }) => {
  const columns = [
    { key: 'images', label: 'Photo', type: 'image' },    
    { key: 'name', label: 'Name', variant: 'title' },
    { key: 'price', label: 'Price', type: 'currency' },
    { key: 'ready_quantity', label: 'Inventory', type: 'number' },
    { key: 'status', label: 'Status' },    
//    { key: 'total_views', label: 'Views', type: 'number' },
  ]
  /*
uid": 45132630253128,
    "key": "gofit-premium-cotton-8600-maroon",
    "name": "Gofit Premium Cotton 8600 Maroon",
    "variant_name": null,
    "product_type": "group",
    "basic_unit": {
      "name": "Piece",
      "value": "piece"
    },
    "shipping_weight": 0.2,
    "product_class": "apparel",
    "primary_image_path": "45114758593096/image/catalog/productfield/cee43f6a-55ad-47c3-bec6-9406bdd7387d.jpeg",
    "primary_image_url": "https://media.dev.goapp.co.id/45114758593096/image/catalog/productfield/cee43f6a-55ad-47c3-bec6-9406bdd7387d.jpeg",
    "ready_quantity": 376,
    "original_price": {
      "min_quantity": 0,
      "price": "39000.00"
    },
    "bulk_prices": [
      {
        "min_quantity": 0,
        "price": "39000.00"
      },
      {
        "min_quantity": 5,
        "price": "30000.00"
      }
    ],
    "business": {
      "uid": 45114758593096
    },
    "stocks": [
      {
        "warehouse": 2,
        "incoming_quantity": 0,
        "outgoing_quantity": 0,
        "total_ready_quantity": 376
      }
    ],
    "channels": [
      {
        "channel": {
          "uid": 45115805542472,
          "name": "In App",
          "channel_type": "inapp"
        },
        "is_visible": true,
        "hide_if_unavailable": false,
        "posted_at": null,
        "created_at": "2019-10-18T06:10:01.775647+07:00"
      }
    ],  
  */
  
  const getCellValue = (row, column) => {
    if (column.key == 'images') {
      const val = row[column.key]
      if (val && val.length > 0)
        //return val[0].image_url
        return (
          <img src={val[0].image_url} style={{
              border: "solid 1px #eeeeee",
              marginTop: theme.spacing(-1),
              marginBottom: theme.spacing(-1),
              maxHeight: '5rem',
              maxWidth: '20rem'
            }} />
        )
    }
    else if (column.key == 'status') {
      if (row['channels'] && row['channels'].length > 0)
        return "Online"
    }
    else if (column.key == 'price')
      return row['original_price'] && row['original_price'].price
      
    return row[column.key]
  }
  
  const getCellSecondaryValue = (row, column) => {
    if (column.key == 'name') {
      let text = ""
      if (row.variants && row.variants.length > 0)
        text = "Variants: " + row.variants.map(v => v.variant_name).join(", ")
      return text
    }    
    else if (column.key == 'namee') {
      const images = row['images']
      if (images && images.length > 0)
        return images.map(img =>
            <img src={img.image_url} style={{
                //marginTop: theme.spacing(-1),
                //marginBottom: theme.spacing(-1),
                maxHeight: '3rem',
                maxWidth: '20rem'
              }} 
            />
          )        
    }
    else if (column.key == "price") {
      if (row.bulk_prices && row.bulk_prices.length > 1) {
        const price = row.bulk_prices[row.bulk_prices.length-1].price
        return "As low as " + formatValue(price, "currency")
      }
    }
    else if (column.key == 'status') {
      if (row['channels'] && row['channels'].length > 0)
        return row['channels'].map(c => c.channel.name).join(", ")
    }    
    else if (column.key == 'ready_quantity')
      if (parseInt(row['ready_quantity']) > 0)
        return "Ready"        
  }
  
  return (
    <TableData
      columns={columns}
      rows={records}
      getCellValue={getCellValue}
      getCellSecondaryValue={getCellSecondaryValue}
      onRowClick={(row) => onClick(row)}
      />
    )  
}

const ProductListController = ({ history, theme, match, classes, user, business, ...props }) => {

  const { lastFetched, fetchProductList, isFetching, data } = props

  const navigationContext = useContext(NavigationContext)

  useEffect(() => {
    fetchProductList()

    return () => {
    }
  }, [])
  
  const showProductDetail = (item) => {
    let url = `/catalog/product/view/${item.key}`
    history.push(url)
  }
  
  const hideProductDetail = () => {
    let url = `/catalog/product`
    history.push(url)
  }

  /*
  if (productKey) {
    if (false) //modelKey == 'page')
      return (
        <PageEditor
          pageKey={contentKey}
          onHide={hideContentEditor}
          />
      )
    else
      return (
        <ContentView
          contentKey={contentKey}
          onHide={hideContentEditor}
          />
      )
  }
  */
    
  return (
    <div>    
      <Container className={classes.container} maxWidth="xl">
        <Box display='flex'>        
          <Box flexGrow={1}>
            <TableView
              classes={classes}
              theme={theme}
              records={data.records}
            />
          </Box>
        </Box>
      </Container>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...getProductList(state),
    user: getUser(state),
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let viewKey = (ownProps.match && ownProps.match.params.viewKey) || "all"

  return {
    fetchProductList: (filter, sort_by, refresh=false) => {
      dispatch(fetchProductList(filter, sort_by, refresh))
    },
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ProductListController)
  )
)
