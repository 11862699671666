import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getContactRecord, fetchContactRecord, fetchContactVisitHistory, updateContactRecord, fetchContactFields, getContactFields } from '../data/reducers/contact'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import BusinessIcon from '@material-ui/icons/Business'
import LanguageIcon from '@material-ui/icons/Language'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})

const HeaderView = ({ theme, classes, isFetching, lastFetched, data}) => {
  
  let status = null
  if (data.active_on)
    status = (
        <>
          <FiberManualRecordIcon style={{ color: 'green', marginRight: theme.spacing(1) }} fontSize='small'/>
          Active from {data.active_on.city}
        </>
        )
  else if (data.last_seen)
    status = (
        <>
          <FiberManualRecordIcon style={{ color: '#aaa', marginRight: theme.spacing(1) }} fontSize='small'/>
          Last seen {moment(data.last_seen).fromNow(true)} ago
          { data.last_seen_on &&
            <>
              &nbsp;from {data.last_seen_on.city}
            </>
          }
        </>
        )

  return (
    <div className={classes.headerRoot}>
      <div style={{ display: 'flex', alignItems: 'top', direction: 'row' }}>
        <div style={{ flexGrow: 1}}>
          <Typography variant='h5'>
            { (data.first_name || data.last_name) ?
              <>{ data.first_name } { data.last_name }</>
              :
              <>New Contact</>
            }
          </Typography>
          { status &&
            <Box mt={1} style={{ display: 'flex', alignItems: 'center' }}>
              { status }
            </Box>
          }
        </div>
        <Box mr={2} />
        <div style={{ width: theme.spacing(10), display: 'flex', justifyContent: 'center' }}>
          <Avatar style={{ width: theme.spacing(10), height: theme.spacing(10) }} />
        </div>
      </div>
    </div>
  )
}

const MoreSectionItem = ({ theme, classes, hasMore, onClick }) => {
  return (
    <ListItem button 
      style={{ padding: 0, display: 'flex', justifyContent: 'center'}}
      onClick={onClick}
      >
      { hasMore ? 
        <ArrowDropDownIcon color='grey' /> 
      : 
        <ArrowDropUpIcon color='grey' /> 
      }
    </ListItem>
  )
}

const SectionItem = ({ theme, classes, item, isEditing, editValue, onChange, onSave, onCancel, onClick }) => {
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current)
      inputRef.current.focus()
  }, [inputRef, isEditing])

  return (
    <ListItem button={!isEditing} alignItems="flex-start" className={classes.listItemView}
      style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
      onClick={onClick}
      >
      { isEditing ?
        <ListItemText
          primary={
            <input
              ref={inputRef}
              style={{
                border: 'none',
                fontSize: '1rem',
                width: '100%',
                backgroundColor: 'transparent',
                padding: '6pt 4pt',
                margin: '0 -4pt'
              }}
              value={editValue}
              onChange={e => onChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13)
                  onSave()
                else if (e.keyCode == 27) {
                  onCancel()
                  e.stopPropagation()
                }
              }}              
            />
          }
          secondary={ item.label }
        />
      :
        <ListItemText
          primary={ item.value }
          secondary={ item.label }
        />
      }
    </ListItem>
  )
}

const SectionCard = ({ theme, classes, title, children, defaultExpanded, contentStyle }) => {
  return (
    <div className={classes.sectionCardRoot}
      style={{ borderTop: 'solid 1px #eee', borderBottom: 'solid 1px #eee'}}
      >
      <ExpansionPanel 
        defaultExpanded={defaultExpanded}
        elevation={0}
        square={1}
        >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.sectionCardHeading}>{ title }</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            padding: `0 ${theme.spacing(1)}px`, 
            marginTop: '-18px',
            ...(contentStyle || {})
          }}
          >
          <List style={{ width: '100%' }}>
            { children }
          </List>

        </ExpansionPanelDetails>
      </ExpansionPanel>    
    </div>
  )  
}

const ContactCard = ({ theme, classes, contactFields, isFetching, lastFetched, data, updateContactRecord }) => {

  const [editData, setEditData] = useState({})
  const [showAllField, setShowAllField] = useState(false)

  const filteredFields = contactFields.data.reduce((p, f) => {
    let type = f.field_type

    if (!(type == 'text' || type == 'number'))
      return p

    let fieldName = f.key.replace("-", "_")
    if (fieldName == 'email')
      type = 'email'

    if (!showAllField && !data[fieldName])
      return p

    return [...p, {
      fieldName,
      type,
      label: f.name
    }]
  }, [])

  useEffect(() => {
    setEditData({})
  }, [data.uid])

  return (
    <SectionCard theme={theme} classes={classes}
      title='Personal Info'
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={{ ...item, value: data[item.fieldName]}}
          isEditing={editData.fieldName == item.fieldName ? true : false}
          editValue={editData.fieldName == item.fieldName ? editData.value : null}
          onChange={(value) => setEditData({...editData, value})}
          onClick={() => setEditData({ fieldName: item.fieldName, value: data[item.fieldName] || null})}
          onSave={() => {
            updateContactRecord({
              [editData.fieldName]: editData.value
            }, () => { setEditData({})})
          }}
          onCancel={() => setEditData({})}
        />
      ))}
      <MoreSectionItem hasMore={!showAllField}
        onClick={() => setShowAllField(!showAllField)}
      />
    </SectionCard>
  )
}


const UTMCard = ({ theme, classes, contactFields, isFetching, lastFetched, data }) => {

  const utmFields = [
    { 'fieldName': 'utm_source', label: 'Source' },
    { 'fieldName': 'utm_medium', label: 'Medium' },
    { 'fieldName': 'utm_campaign', label: 'Campaign' },
    { 'fieldName': 'utm_term', label: 'UTM Term' },
    { 'fieldName': 'utm_content', label: 'UTM Content' },
    { 'fieldName': 'referer_host', label: 'Referer' },
  ]

  const filteredFields = utmFields.reduce((p, f) => {
    if (!data[f.fieldName])
      return p
    return [...p, f]
  }, [])

  return (
    <SectionCard theme={theme} classes={classes}
      title='Source'
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
        />
      ))}
    </SectionCard>
  )
}

const CompanyCard = ({ theme, classes, isFetching, lastFetched, data }) => {

  const companyList = [
//    { name: 'GoFit Apparel', position: 'Director', email: 'test@example.com' },
  ]

  return (
    <SectionCard theme={theme} classes={classes}
      title={`Work Info (${companyList.length})`}
      defaultExpanded={false}
      contentStyle={{ paddingTop: 0, paddingBottom: 0, paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
      >
      { companyList.map(company => (
        <List style={{ width: '100%' }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BusinessIcon fontSize='small' /> &nbsp;
                { company.name }
              </div>
            </ListSubheader>
          }
          >
          <ListItem button alignItems="flex-start" className={classes.listItemView}
            style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
            >
            <ListItemText
              primary={ company.email }
              secondary={ 'Work Email' }
            />
          </ListItem>
        </List>        
      ))}
    </SectionCard>
  )
}

const VisitHistory = ({ theme, classes, isFetching, lastFetched, data, visitHistory }) => {

  let activityList = []
  if (visitHistory)
    activityList = visitHistory.data
    .map(e => {
      let timeFromNow = moment(e.created_at).fromNow(true)
      timeFromNow = timeFromNow.replace(" hours", "h")
      timeFromNow = timeFromNow.replace("an hour", "1h")
      timeFromNow = timeFromNow.replace(" minutes", "m")
      timeFromNow = timeFromNow.replace(" minute", "m")
      timeFromNow = timeFromNow.replace(" seconds", "s")
      timeFromNow = timeFromNow.replace(" days", "d")
      timeFromNow = timeFromNow.replace("a day", "1d")
      timeFromNow = timeFromNow.replace(" months", "mo")
      timeFromNow = timeFromNow.replace("a month", "1mo")
      
      let type = e.event_name
      let description
      if (e.event_name == 'view') {
        data = JSON.parse(e.event_data)
        if (data.type == 'content') {
          if (data.model == 'page')
            type = 'Page'
          else if (data.model == 'blog')
            type = 'Blog Post'
          else
            type = 'Content Page'
        }
        else if (data.type == 'product')
          type = 'Product Page'
          
        if (data.title)
          description = data.title          
      }
      
      return {
        type,
        description,
        timeFromNow
        }
      })

  return (
    <SectionCard theme={theme} classes={classes}
      title='Recent Visits'
      defaultExpanded={true}
      >
      { activityList.map(item => (
        <ListItem button alignItems="flex-start" className={classes.listItemView}
          style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
          >
          <ListItemText
            primary={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ width: '2em', display:'flex', alignItems: 'center', flexShrink: 0 }}>
                  <LanguageIcon fontSize='small' style={{ color: '#aaa' }}/>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                  <Typography variant='body2' component='span'>{ item.description || item.type }</Typography>
                </Box>
                <Box style={{ width: '3em', flexShrink: 0 }}>
                  <Typography variant='body2' style={{ color: '#aaa', textAlignment: 'right'}} component='span'>{ item.timeFromNow }</Typography>
                </Box>
              </div>
            }
          />
        </ListItem>
      ))}
    </SectionCard>
  )
}

const ContactSideView = ({ theme, classes, contactKey, fetchContactRecord, fetchContactFields, isFetching, lastFetched, contactFields, data, visitHistory, initialData, updateContactRecord }) => {

  useEffect(() => {
    fetchContactRecord()
    fetchContactFields()
  }, [contactKey])

  if (!lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        <CircularProgress size='1.5rem' disableShrink={true} />
      </div>
    )
  }

  const dataProps = { isFetching, lastFetched, data, visitHistory, updateContactRecord }

  return (
    <div className={classes.root}>
      <HeaderView theme={theme} classes={classes} {...dataProps} />
      <Box mt={3} />
      <ContactCard theme={theme} classes={classes} contactFields={contactFields} {...dataProps} />
      {/*
      <Box mt={1} />
      <CompanyCard theme={theme} classes={classes} {...dataProps} />
      */}
      <Box mt={1} />
      <VisitHistory theme={theme} classes={classes} {...dataProps} />
      <Box mt={1} />
      <UTMCard theme={theme} classes={classes} {...dataProps} />
    </div>    
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    ...getContactRecord(state, ownProps.contactKey),
    contactFields: getContactFields(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContactRecord: () => { dispatch(fetchContactRecord(ownProps.contactKey)) },
    fetchContactVisitHistory: () => { dispatch(fetchContactVisitHistory(ownProps.contactKey)) },
    updateContactRecord: (data, onComplete) => { dispatch(updateContactRecord(ownProps.contactKey, data, onComplete)) },
    fetchContactFields: () => { dispatch(fetchContactFields()) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactSideView)
)