import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'

import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import moment from 'moment'
import classNames from 'classnames'

import MessageItem from './MessageItem'
import ChatMessage from './ChatMessage'
import ReactLoading from 'react-loading'
import Reveal from 'react-reveal/Zoom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

const styles = theme => ({
  root: {
    width: '100%',
//    maxWidth: 500,
    padding: theme.spacing.unit * 2,
    backgroundColor: '#eee',
  },

  messageTop: {
//    minHeight: '70vh',
//    [theme.breakpoints.up('sm')]: {
//      minHeight: '80vh'
//    }

    minHeight: '50vh',
    [theme.breakpoints.up('sm')]: {
//      minHeight: '30vh'
      minHeight: '40vh'
    }
  },
  muted: {
    color: '#999'
  },
})

export const parseBody = (body) => {

  if (body && body.startsWith("{") && body.endsWith("}")) {
    let data = JSON.parse(body)
    if (data['input_type']) {
      data.componentType = data['input_type'] + "_input"
      data.isInputComponent = true
    }
    else if (data['card_list'] )
      data.componentType = 'card_list'
    else if (data['card'])
      data.componentType = 'card'
    else
      data.componentType = null

    return data
  }
  else
    return {
      text: body || ""
    }
}

const groupMessages = (messages) => {

  let groupedMessages = []
  if (messages.length > 0) {
    groupedMessages = messages.reduce((p, n) => {
      n.content = parseBody(n.data.body)
      n.next = null // reset
      
      if (p.length > 0 && !n.isSending) {
        let lastItem = p[p.length-1]
        while (lastItem.next)
          lastItem = lastItem.next
        let sent_interval = moment(n.data.sent_at).diff(moment(lastItem.data.sent_at))
        if (!lastItem.isSending) {
          if ((n.data.sender.agent && lastItem.data.sender.agent && n.data.sender.agent.uid == lastItem.data.sender.agent.uid) ||
              (n.data.sender.contact && lastItem.data.sender.contact)) {
            if (!lastItem.content.componentType && sent_interval <= 30000) {  
              lastItem.next = n
              return p
            }
          }
        }
      }
    
      return [...p, n]
    }, [])
  } 

  return groupedMessages 
}

const MessageList = ({ classes, theme, business, user, conversation, initialMessage, containerHeight=0, ...props }) => {

  const actionProps = {
    openLink: props.openLink,
    sendMessage: props.sendMessage,
  }

  const bottomDiv = useRef()
  const listDiv = useRef()

  const [ topPadding, setTopPadding ] = useState(-1)
  
  const scrollToBottom = () => {
    if (bottomDiv.current)
//    alert("Scroll to bottom")
    //listDiv.current.scrollTop = listDiv.current.scrollHeight;
      bottomDiv.current.scrollIntoView()
    //if (listDiv.current)
    //  listDiv.current.scrollTop = listDiv.current.scrollHeight;
  }

  useEffect(() => {
    // scrollToBottom()
  }, [listDiv])

  const parties = conversation.data.parties || []
  const groupedMessages = groupMessages([
    ...conversation.messages, ...conversation.pendingPosts
    ])

  let typingAt = 0
  let typingCount = 0
  const now = new Date()
  parties.forEach(p => {
    if ((user.userInfo.is_staff && p.agent) ||
        (!user.userInfo.is_staff && p.contact))
      return

    if (p.typing_at) {
      const date = moment(p.typing_at).toDate()
      if ((now - date) <= 10000) {
        if (date > typingAt)
          typingAt = date
        typingCount += 1
      }
    }
  })
  
  // Scroll to bottom when message is added or typing parties changed
  let messagesLength = conversation.messages.length + conversation.pendingPosts.length + typingCount << 8
  
  useEffect(() => {
    scrollToBottom()
  }, 
    [messagesLength, topPadding, conversation.key]
  ) 
  
  let businessAvatar = business.avatar == undefined ? null : business.avatar.image_url

  // Start timer to handle typingAt expiry
  const [typingTimeout, setTypingTimeout] = useState()

  useEffect(() => {
    if (typingAt && !typingTimeout) {
      const interval = now - typingAt
      if (interval < 10000) {
        if (typingTimeout)
          clearTimeout(typingTimeout)
        setTypingTimeout(setTimeout(() => {
          //alert('timeout')
          setTypingTimeout(null)
        }, 10000 - interval))
      }
    }
  }, [typingAt])
  
  return (
    <div className={classes.root}
      ref={listDiv}
      style={{
        height: containerHeight,
        position: 'absolute',
        //backgroundColor: 'red',
        overflow: 'scroll',
      }}
      >
      <div className={classes.messageTop} style={{ minHeight: containerHeight }} />

      <div style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        { conversation.isFetching &&
          <CircularProgress color='gray' disableShrink={true} size='1.5rem'/>
        }
      </div>

      {/*
      <div className={classes.messageTop} style={{ minHeight: containerHeight-40 }} />
      <Typography variant='body1' className={classes.chatInfo} gutterBottom>
      
      { initialMessage }
      
      </Typography>
      */}

      <div style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          //transform: 'scale(1,-1)'
          //flexWrap: 'wrap-reverse'
        }}>
        
        { typingAt > 0 &&
          <ChatMessage
            side='left'
            avatar={businessAvatar}
            messages={[
              (<Box style={{ height: 32, position: 'relative', top: -16 }}>
                  <ReactLoading type='bubbles' color='#555' width={64} height={64} />
              </Box>
              )
            ]}
          />
        }

        { groupedMessages.length > 0 &&
          groupedMessages.reverse().map((item, index) =>
            <MessageItem 
              item={item} 
              user={user}
              showInputComponent={index == 0/*(groupedMessages.length-1)*/ ? true : false}
              { ...actionProps }
              businessAvatar={businessAvatar}
             />
          )
        }

      </div>
      <div ref={bottomDiv} />
    </div>
  )
}

export default withResizeDetector(withStyles(styles, { withTheme: true })(
  MessageList
  ))
