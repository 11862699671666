import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getContentIndex, fetchContentIndex } from '../data/reducers/content'

import { NavigationContext } from './NavigationController'

import GeneralSettingView from '../views/GeneralSettingView'
import AppsSettingView from '../views/AppsSettingView'
import QueuesSettingView from '../views/QueuesSettingView'
import TeamSettingView from '../views/TeamSettingView'
import ChannelsSettingView from '../views/ChannelsSettingView'
import OperatorsSettingView from '../views/OperatorsSettingView'

import FilterBar from '../views/FilterBar'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import ListIcon from '@material-ui/icons/ViewList'
import EventIcon from '@material-ui/icons/Event'

import TeamIcon from '@material-ui/icons/Group'
import GeneralIcon from '@material-ui/icons/Business'
import ChannelIcon from '@material-ui/icons/AlternateEmail'
import ApplicationIcon from '@material-ui/icons/Devices'
import MessengerIcon from '@material-ui/icons/Chat'
import OperatorIcon from '@material-ui/icons/DirectionsRun'
import QueueIcon from '@material-ui/icons/Inbox'
import OutboundIcon from '@material-ui/icons/Send'


const styles = theme => ({
  root: {
  },
  sectionTitle: {
    fontWeight: 700
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  cardView: {
    // Larger bottom to accomodate shadow
    paddingBottom: theme.spacing(8)
  },
  card: {
  },
  cardTitle: {
    fontWeight: 500,
  },
  counterText: {
    color: 'black',
    fontWeight: 500
  },
  tableView: {

  },
  calendarView: {
  },

})

const SettingsController = ({ history, theme, match, classes, user, business, ...props }) => {

  const navigationContext = useContext(NavigationContext)

  const urlParams = new URLSearchParams(props.location.search)
  const viewType = match.params.viewType || 'general'
  
  const viewTypes = [
    { key: 'general', title: 'General', icon: GeneralIcon, component: GeneralSettingView },
    { key: 'channels', title: 'Channels', icon: ChannelIcon, component: ChannelsSettingView },
    { key: 'queues', title: 'Queue', icon: QueueIcon, component: QueuesSettingView },
    { key: 'operator', title: 'Operators', icon: OperatorIcon, component: OperatorsSettingView },
    { key: 'apps', title: 'Application', icon: ApplicationIcon, component: AppsSettingView },
//    { key: 'messenger', title: 'Messenger', icon: MessengerIcon, component: MessengerSettingView },    
    { key: 'team', title: 'Users', icon: TeamIcon, component: TeamSettingView },
//    { key: 'outbound', title: 'Outbound', icon: OutboundIcon },
  ]

  let viewComponent = viewTypes.find(v => v.key == viewType).component || GeneralSettingView

  const view = React.createElement(viewComponent, {
    theme, classes,
  })

  return (
    <div>
      <FilterBar
        leftItems={(
          <>
            { viewTypes.map((item, index) =>
              <Box 
                className={item.key == viewType ? classes.menuItemSelected : classes.menuItemDefault}>
                <Button style={{ color: item.key == viewType ? 'black' : 'gray'}}
                  className={item.key == viewType ? classes.filterTitleButtonBold : classes.filterTitleButton}
                  component={Link}
                  to={`/settings/${item.key}`}
                  >
                  { item.icon && React.createElement(item.icon, {
                      color: 'inherit',
                      style: { marginRight: theme.spacing(0.5) }                    
                    })
                  }
                  { item.title }
                </Button>
              </Box>
              )}
          </>
        )}
        />

      <Container className={classes.container} maxWidth="md">
        { view }
      </Container>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SettingsController)
  )
)
