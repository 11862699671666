import 'react-big-calendar/lib/css/react-big-calendar.css'

import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getContentIndex, fetchContentIndex } from '../data/reducers/content'

import { NavigationContext } from './NavigationController'

import FilterBar from '../views/FilterBar'
import ContentView from '../views/ContentView'
import PageEditor from '../views/PageEditor'

import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'

import TableData from '../views/TableData.js'

import ListIcon from '@material-ui/icons/ViewList'
import EventIcon from '@material-ui/icons/Event'

import PageIcon from '@material-ui/icons/Pages'
import ContentIcon from '@material-ui/icons/Movie'
import SocialPostIcon from '@material-ui/icons/Mms'
import OutboundIcon from '@material-ui/icons/Send'

const localizer = momentLocalizer(moment)

const styles = theme => ({
  root: {
  },
  sectionTitle: {
    fontWeight: 700
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  cardView: {
    // Larger bottom to accomodate shadow
    paddingBottom: theme.spacing(8)
  },
  card: {
  },
  cardTitle: {
    fontWeight: 500,
  },
  counterText: {
    color: 'black',
    fontWeight: 500
  },
  tableView: {

  },
  calendarView: {
  },

})
const TableView = ({ theme, classes, records, lastFetched, isFetching, onEditContent, onClick }) => {
  const columns = [
    { key: 'title', label: 'Title', variant: 'title' },
    { key: 'status', label: 'Status' },
    { key: 'total_views', label: 'Views', type: 'number' },
  ]
  
  const getCellValue = (row, column) => {
    return row[column.key]
  }
  
  const getCellSecondaryValue = (row, column) => {
  }
  
  return (
    <TableData
      columns={columns}
      rows={records}
      getCellValue={getCellValue}
      getCellSecondaryValue={getCellSecondaryValue}
      onRowClick={(row) => onClick(row)}
      />
    )  
}

const CalendarView = ({ theme, classes, records, lastFetched, isFetching, onEditContent }) => {

  const events = records.map(item => ({
    title: item.title,
    start: item.created_at,
    allDay: true
  }))

  return (
    <div className={classes.calendarView}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
      />
    </div>
  )
}

const CardItem = ({ theme, classes, item, onEdit }) => {

  return (
    <Card className={classes.root} elevation={18}>
      <CardContent>
        <Typography variant="h6" component="h1" className={classes.cardTitle}>
          { item.title }
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <span class={classes.counterText}>{ item.total_view }</span> views ⸱ 0 visits
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" onClick={onEdit}>Edit</Button>
      </CardActions>
    </Card>
  )  
}

const CardView = ({ theme, classes, records, lastFetched, isFetching, onClick }) => {

  return (
    <div className={classes.cardView}>
      { lastFetched &&
        <>
          <Box mt={4} mb={2}>
            <Typography component="h1" variant="body1" className={classes.sectionTitle}>
              Draft
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Grid container className={classes.root} spacing={4}>
              { lastFetched && records.filter(p => (
                  p.posts.length <= 0
                )).map(p =>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <CardItem theme={theme} classes={classes}
                    item={p}
                    onEdit={()=>onClick(p)}
                    />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box mt={4} mb={2}>
            <Typography component="h1" variant="body1" className={classes.sectionTitle}>
              Published
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Grid container className={classes.root} spacing={4}>
              { lastFetched && records.filter(p => (
                  p.posts.length > 0
                )).map(p =>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <CardItem theme={theme} classes={classes}
                    item={p}
                    onEdit={()=>onClick(p)}
                    />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      }

    </div>  
  )
}

const ContentsController = ({ history, theme, match, classes, user, business, ...props }) => {

  const { viewType, contentKey, modelKey, lastFetched, fetchContentList, isFetching, data } = props
  
  const navigationContext = useContext(NavigationContext)

  const urlParams = new URLSearchParams(props.location.search)
  // const viewType = urlParams.get('view') || 'page'

  useEffect(() => {
    fetchContentList()

    return () => {
    }
  }, [viewType])

  const viewTypes = [
    { key: 'page', title: 'Page', icon: <PageIcon color='inherit' />, component: CardView },
    { key: 'content', title: 'Content', icon: <ContentIcon color='inherit' />, component: TableView },
//    { key: 'social-post', title: 'Social Post', icon: <SocialPostIcon color='inherit' />, component: TableView },
    { key: 'outbound', title: 'Outbound', icon: <OutboundIcon color='inherit' />, component: TableView },
/*
    { key: 'calendar', title: 'Calendar', icon: <EventIcon color='inherit' />, component: CalendarView }
*/
  ]

  
  const modelList = [
    { key: 'page', title: 'Pages' },
    { key: 'blog', title: 'Articles' },
    { key: 'video-post', title: 'Videos' },
    { key: 'social-post', title: 'Social Post' },
    { key: 'newsletter', title: 'Email Newsletter' },
    { key: 'push-message', title: 'Push Message' },
    { key: 'banner', title: 'Banner' },
//    { key: 'portfolio', title: 'Portfolio Page' },
  ]

  const editContent = (item) => {
    let url = `/campaign/${viewType}/${item.model}/view/${item.uid}`
    history.push(url)
  }
  
  const hideContentEditor = () => {
    let url = `/campaign/${viewType}`
    history.push(url)
  }

  if (contentKey) {
    if (modelKey == 'page')
      return (
        <PageEditor
          pageKey={contentKey}
          modelKey={modelKey}
          onHide={hideContentEditor}
          />
      )
    else
      return (
        <ContentView
          modelKey={modelKey}
          contentKey={contentKey}
          onHide={hideContentEditor}
          />
      )
  }

  let records = []
  if (lastFetched) {
    if (viewType == 'page')
//      records = data.records.filter(p => p.component == 'Page')
      records = data.records.filter(p => p.model == 'page')
    else if (viewType == 'content')
      records = data.records.filter(p => ['blog', 'article', 'video', 'podcast'].includes(p.model))
    else if (viewType == 'social-post')
      records = data.records.filter(p => p.model in ['social-update', 'social-story'])
    else if (viewType == 'outbound')
      records = data.records.filter(p => p.model in ['email-newsletter', 'push-message', 'sms-broadcast'])
    /*
    else if (modelKey == 'page')
      records = data.records.filter(p => p.model in ['blog', 'article'])
    else if (modelKey == 'all' || !modelKey)
      records = data.records.filter(p => {
        if (modelList.find(m => m.key == p.model))
          return true
        else
          return false
      })
    */
    else
      records = data.records
  }

  let viewComponent = viewTypes.find(v => v.key == viewType).component

  const view = React.createElement(viewComponent, {
        theme, classes,
        lastFetched, isFetching,
        records,
        onClick: editContent
      })

  return (
    <div>
      <FilterBar
        leftItems={(
          <>
            { viewTypes.map((item, index) =>
              <Box 
                className={item.key == viewType ? classes.menuItemSelected : classes.menuItemDefault}>
                <Button style={{ color: item.key == viewType ? 'black' : 'gray'}}
                  className={item.key == viewType ? classes.filterTitleButtonBold : classes.filterTitleButton}
                  component={Link}
                  to={`/campaign/${item.key}`}
                  >
                  { item.icon }
                  { item.title }
                </Button>
              </Box>
              )}
          </>
        )}
        />

      <Container className={classes.container} maxWidth="xl">
        <Box display='flex'>
          {/*
          <Box style={{ minWidth: '280px', width: '20%' }}>

            <Box
              mt={1}              
              >
              <Typography variant='h6' component='h1'>
                Campaign Type
              </Typography>
            </Box>

            <Box
              mt={1}              
              >
              <List
                style={{ paddingTop: 0 }}
                >
                { modelList.map(item => (
                  <ListItem alignItems="flex-start"
                    style={{ paddingLeft: 0 }}
                    >
                    <ListItemText
                      primary={ 
                        <>
                          <Typography variant='body1'>
                          { item.title }
                          </Typography>                
                        </>
                      }
                    />
                  </ListItem>
                  ))
                }
              </List>
            </Box>
          </Box>
          */}

          <Box flexGrow={1}>
            { view }
          </Box>
        </Box>
      </Container>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let viewType = (ownProps.match && ownProps.match.params.viewType) || "page"
  let contentKey = ownProps.match && ownProps.match.params.contentKey
  let modelKey = ownProps.match && ownProps.match.params.modelKey
    
  let contentIndex = {}
  if (viewType)
    contentIndex = getContentIndex(state, "all", 'default')

  return {
    ...contentIndex,
    viewType,
    contentKey,
    modelKey,
    user: state.user,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let viewKey = (ownProps.match && ownProps.match.params.viewKey) || "all"

  return {
    fetchContentList: (refresh=false) => {
      dispatch(fetchContentIndex("all", 'default', null, refresh))
    },
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ContentsController)
  )
)
