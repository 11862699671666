import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSetting, updateSetting, addSetting, fetchSetting } from '../data/reducers/setting'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { SectionListView, MoreSectionItem, SectionItem, SectionCard } from './SettingView'
import { validateInput } from './SettingView'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'

import UserIcon from '@material-ui/icons/Person'
import UserAddIcon from '@material-ui/icons/PersonAdd'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = theme => ({
  root: {
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})

const InputField = ({ theme, classes, inputType, name, label, value, options, error, onChange}) => {

  if (inputType == 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(event) => onChange(event.target.checked)}
            name={name}
            color="primary"
            />
        }
        label={label}
      />      
    )    
  }
  else if (inputType == 'option') {
    return (
      <FormControl fullWidth className={classes.formControl}>
       <InputLabel>{label}</InputLabel>
        <Select
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
        >
          { options.map((o) => (
            <MenuItem value={o.value}>{o.text}</MenuItem>
          ))}            
        </Select>
      </FormControl>
    )
  }
  else if (inputType == 'radio') {
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup name={name} value={value || ""} 
          onChange={(event) => onChange(event.target.value)}
          row>
        
          { options.map((o) => (
            <FormControlLabel
              control={<Radio color="primary" />}
              value={o.value}
              label={o.text}
            />
          ))}
          
        </RadioGroup>
      </FormControl>
    )
  } 
  else {
    return (
      <TextField
        label={label}
        value={value || ""}
        error={error}
        type={inputType}
        onChange={(event) => onChange(event.target.value)}
        className={classes.textField}
        margin="normal"
        fullWidth
      />      
    )    
  }
}

const ResetPasswordForm = ({ theme, classes, open, onClose }) => {

  const [formValues, setFormValues] = useState({
    'auto_generate_password': true,
    'require_reset_password': true,
  })

  const handleFormChange = (name) => (value) => {
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
      <DialogContent>  
        <Box display='flex' flexDirection='column'>
          <InputField name='auto_generate_password'
            theme={theme} classes={classes}
            inputType='checkbox'
            label='Auto generate password'
            value={formValues['auto_generate_password']}
            onChange={handleFormChange('auto_generate_password')}
            />
          <InputField name='require_reset_password'
            theme={theme} classes={classes}
            inputType='checkbox'
            label='Require reset password on next login'
            value={formValues['require_reset_password']}
            onChange={handleFormChange('require_reset_password')}
            />

          { !formValues.auto_generate_password &&
            <>
              <InputField name='password'
                theme={theme} classes={classes}
                inputType='password'
                label='Password'
                value={formValues['password']}
                onChange={handleFormChange('password')}
                />
              <InputField name='confirm_password'
                theme={theme} classes={classes}
                inputType='password'
                label='Confirm password'
                value={formValues['confirm_password']}
                onChange={handleFormChange('confirm_password')}
                />
            </>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>        
  )
}

const AddUserForm = ({ theme, classes, open, onClose, saveUser }) => {

  const [formValues, setFormValues] = useState({
    'auto_generate_password': true,
    'require_reset_password': true,
  })
  const [errorFields, setErrorFields] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [generatedPassword, setGeneratedPassword] = useState(null)

  const handleFormChange = (name) => (value) => {
    setFormValues({
      ...formValues,
      [name]: value
    })
  }
  
  const onSave = () => {
    let newErrors = {}
    if (!validateInput(formValues.first_name, true))
      newErrors.first_name = "First Name is required"
    if (!validateInput(formValues.last_name, true))
      newErrors.last_name = "Last Name is required"
    if (!validateInput(formValues.email, true, 'email'))
      newErrors.email = "Please enter a valid Email"
    if (!formValues.auto_generate_password) {
      if (!validateInput(formValues.password, true, 'password')) {
        newErrors.password = "Please enter a valid Password"
        newErrors.confirm_password = "Please enter a valid Password"
      }
      else if (formValues.confirm_password !== formValues.password)
        newErrors.confirm_password = "Confirm Password is not save with Password"        
    }
    
    setErrorFields(newErrors)
    if (Object.keys(newErrors).length > 0)
      return
    
    setIsSaving(true)
    let data = {...formValues, is_staff: true}
    if (formValues.auto_generate_password) {
      // Generate password
      const password = "pas1234!"
      data.password = password
      data.confirm_password = password
    }
    
    saveUser(data, (result, error) => {
      setIsSaving(false)
      if (error) {
        alert(error)
      }
      else {
        if (data.auto_generate_password) {
          const password = data.password
          // Reveal new password
          setGeneratedPassword(password)
        }
        else        
          onClose()
      }
    })
  }
  
  const onCancel = () => {
    if (Object.keys(formValues).length > 0) {
      if (!window.confirm("Discard changes?"))
        return      
    }
  
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      { isSaving &&
        <LinearProgress />
      }
        
      <DialogTitle id="form-dialog-title">Add User</DialogTitle>
      <DialogContent>
        { generatedPassword ?
          <Box display='flex' flexDirection='column'>
            Please copy the new password below and keep it save.
            <Typography>
            { generatedPassword }
            </Typography>
          </Box>
        :      
          <Box display='flex' flexDirection='column'>
            <InputField name='first_name'
              theme={theme} classes={classes}
              inputType='text'
              label='First Name'
              value={formValues['first_name']}
              onChange={handleFormChange('first_name')}
              error={errorFields.first_name}
              />
            <InputField name='last_name'
              theme={theme} classes={classes}
              inputType='text'
              label='Last Name'
              value={formValues['last_name']}
              onChange={handleFormChange('last_name')}
              error={errorFields.last_name}
              />
            <InputField name='email'
              theme={theme} classes={classes}
              inputType='text'
              validation='email'
              label='Email'
              value={formValues['email']}
              onChange={handleFormChange('email')}
              error={errorFields.email}
              />
            <InputField name='mobile_no'
              theme={theme} classes={classes}
              inputType='text'
              label='Mobile No'
              value={formValues['mobile_no']}
              onChange={handleFormChange('mobile_no')}
              error={errorFields.mobile_no}
              />
            <InputField name='auto_generate_password'
              theme={theme} classes={classes}
              inputType='checkbox'
              label='Auto generate password'
              value={formValues['auto_generate_password']}
              onChange={handleFormChange('auto_generate_password')}
              />
            <InputField name='require_reset_password'
              theme={theme} classes={classes}
              inputType='checkbox'
              label='Require reset password on next login'
              value={formValues['require_reset_password']}
              onChange={handleFormChange('require_reset_password')}
              />
  
            { !formValues.auto_generate_password &&
              <>
                <InputField name='password'
                  theme={theme} classes={classes}
                  inputType='password'
                  label='Password'
                  value={formValues['password']}
                  onChange={handleFormChange('password')}
                  error={errorFields.password}
                  />
                <InputField name='confirm_password'
                  theme={theme} classes={classes}
                  inputType='password'
                  label='Confirm password'
                  value={formValues['confirm_password']}
                  onChange={handleFormChange('confirm_password')}
                  error={errorFields.confirm_password}
                  />
              </>
            }
          </Box>
        }
      </DialogContent>
      <DialogActions>
        { generatedPassword ?
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        :
          <>        
            <Button onClick={onCancel} color="primary" disabled={isSaving}>
              Cancel
            </Button>
            <Button onClick={onSave} color="primary" disabled={isSaving}>
              Save
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>        
  )
}

const UserSettingCard = ({ theme, classes, data, updateSetting, showDialog, hideDialog }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)

  const renderPasswordValue = (item, value) => {
    let text = value ? "Yes" : "No"
    
    return (
      <Box display='flex' alignItems='center'>
        <Box flexGrow={1}>
          <Typography>{ text }</Typography>
        </Box>
        <Button variant='outlined' color='primary'
          onClick={() => showDialog(
            <ResetPasswordForm theme={theme} classes={classes} open={true} onClose={hideDialog} />
          )}
          >
          Reset Password
        </Button>
      </Box>
    )
  }
    
  const fields = [
    { fieldName: 'first_name', type: 'text', label: 'First Name', required: true },
    { fieldName: 'last_name', type: 'text', label: 'Last Name', required: true },
    { fieldName: 'email', type: 'text', validation: 'email', label: 'Email', required: true },
    { fieldName: 'mobile_no', type: 'text', label: 'Mobile No' },
    { fieldName: 'has_password', type: 'text', label: 'Has Password', renderValue: renderPasswordValue },
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])


  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting(editData)
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }  
  
  return (
    <SectionCard theme={theme} classes={classes}
      title={data.first_name + ' ' + data.last_name || ''}
      icon={UserIcon}
      defaultExpanded={false}
      detail={
        <>
          { data.is_superuser &&
            <Box mr={2}>
              <Typography color="textSecondary" variant="body2" style={{ color: '#000' }}>
               Owner
              </Typography>
            </Box>
          }
          <Box style={{ minWidth: '80px' }}>
            <Typography color="textSecondary" variant="body2" style={{ color: '#888' }}>
            { data.last_login ?
              <>
                Login { moment(data.last_login).fromNow(false) }
              </>
            :
              <>
                Never login
              </>
            }
            </Typography>
          </Box>
        </>
      }
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
    </SectionCard>
  )
}

const TeamSettingView = ({ theme, classes, fetchTeamSetting, teamSetting, updateTeamSetting, addTeamSetting }) => {

  useEffect(() => {
    fetchTeamSetting()
  }, [])

  const [activeDialog, setActiveDialog] = useState()

  const showDialog = (dialog) => {
    setActiveDialog(dialog)
  }

  const hideDialog = () => {
    setActiveDialog(null)
  }

  if (!teamSetting.lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        { teamSetting.isFetching &&
          <CircularProgress size='1.5rem' disableShrink={true} />
        }
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <SectionListView title="Users"
        actionButtons={
          <Button color='primary' variant='outlined'
            onClick={() => showDialog(
              <AddUserForm theme={theme} classes={classes} open={true} onClose={hideDialog} saveUser={addTeamSetting} />
            )}
            >
            <UserAddIcon />
            &nbsp;
            Add User
          </Button>
        }
        >
        { teamSetting.data.map(user =>
          <UserSettingCard theme={theme} classes={classes} data={user}
            updateSetting={data => updateTeamSetting({uid: user.uid, ...data})}
            showDialog={showDialog}
            hideDialog={hideDialog}
          />
        )}
      </SectionListView>

      { activeDialog }
    </div>    
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    teamSetting: getSetting(state, 'users'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchTeamSetting: () => { dispatch(fetchSetting('users', true)) },
    updateTeamSetting: (data, onComplete) => { dispatch(updateSetting('users', data, onComplete)) },
    addTeamSetting: (data, onComplete) => { dispatch(addSetting('users', data, onComplete)) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamSettingView)
)
