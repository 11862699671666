import React, { Component, useState, useRef, useEffect, useContext } from 'react'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  root: {
  },
})  

export const validateInput = (value, required, validation) => {
  let val = value    
  if (val === null || val === undefined)
    val = ""
  if (typeof val === 'string' || val instanceof String) {
    val = val.trim()
    if (required && val.length == 0)
      return false
    if (val.length > 0) {
      if (validation === 'email') {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(val).toLowerCase()))
          return false
      }
    }
  }
  
  return true
}

const InputField = ({ theme, classes, type, name, label, gutterBottom, value, options, error, onChange, readOnly }) => {

  if (type == 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(event) => onChange(event.target.checked)}
            name={name}
            color="primary"
            />
        }
        label={label}
      />      
    )    
  }
  else if (type == 'option') {
    const selectControl = (
        <Select
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
          >
          { options.map((o) => o.constructor == Object ?
              <MenuItem value={o.value || o.key}>{o.text || o.name}</MenuItem>
              :
              <MenuItem value={o}>{o}</MenuItem>
            )
          }
        </Select>    
      )
      
    if (label)
      return (
        <FormControl fullWidth className={classes.formControl}>
         <InputLabel>{label}</InputLabel>
          { selectControl }
        </FormControl>
      )
    else
      return selectControl
  }
  else if (type == 'radio') {
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup name={name} value={value || ""} 
          onChange={(event) => onChange(event.target.value)}
          row>
        
          { options.map((o) => (
            <FormControlLabel
              control={<Radio color="primary" />}
              value={o.value}
              label={o.text}
            />
          ))}
          
        </RadioGroup>
      </FormControl>
    )
  } 
  else {
    return (
      <TextField
        label={label}
        value={value || ""}
        error={error}
        type={type}
        multiline={type=='textarea' ? true : false}
        rows={6}
        onChange={(event) => onChange(event.target.value)}
        className={classes.textField}
        margin="normal"
        fullWidth
        readOnly={readOnly}
      />      
    )    
  }
}

export default withStyles(styles)(InputField)