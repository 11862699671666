import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { appReducer } from './data/reducer'
import conversationMiddleware from './middleware/conversation'
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import App from './App'
//import App from './InboxApp'

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import * as serviceWorker from './serviceWorker'

const store = createStore(
  appReducer,
  applyMiddleware(thunk, conversationMiddleware, logger)
  );

const renderApp = (props={}, container) => {
  if (!container) {
    container = document.createElement('div')
    document.body.appendChild(container)
  }

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>  
          <App {...props} />
        </Router>
      </ThemeProvider>
    </Provider>   
    , container);  
}

let container = document.getElementById('goapp-root')
if (container)
  renderApp({
//    sso_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImJpZCI6NDUxMTQ3NTg1OTMwOTZ9.eyJpYXQiOjE1OTMyNTk5OTIsImp0aSI6IjkyNjA4YTZlLWI4NmYtMTFlYS05ODY4LTAyNDJhYzEyMDAwMiIsInVpZCI6MCwibmFtZSI6IkpvaGFuZXMgU3VuZyIsImVtYWlsIjoiam9lQGdvZml0YXBwYXJlbC5jb20ifQ.L2x5bfCLvT_z4nMQyL0CDW9EP2--ChAZkiP-KUpGIGM"      
    }, container)
else {
  window.goappInbox = {
    init: (props, container) => {
      renderApp(props, container)
    }
  }
  // Just for backward compatibility
  window.goappInboxInit = window.goappInbox.init
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
