import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { NavigationContext } from './NavigationController'

import RealTimeDataView from '../views/RealTimeDataView'
import HistoricalDataView from '../views/HistoricalDataView'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import moment from 'moment'

const styles = theme => ({
  root: {
  },
  container: {

  }
})

const DashboardController = ({ theme, classes, user, business, ...props }) => {

  const navigationContext = useContext(NavigationContext)

  useEffect(() => {
    navigationContext.setTransparentBackground(true)

    return () => {
      navigationContext.setTransparentBackground(false)
    }

  }, [])

  const startDate = '2019-1-1'
  const endDate = '2020-6-30'

  const visitorAppTable = [
    { label: 'App', key: 'name' },
    { label: 'Count', key: 'count', type: 'number' },
  ]
  const visitorCityTable = [
    { label: 'City', key: 'name' },
    { label: 'Count', key: 'count', type: 'number' },
  ]
  const visitorMediumTable = [
    { label: 'Medium', key: 'name' },
    { label: 'Count', key: 'count', type: 'number' },
  ]
  const contactContactTable = [
    { label: 'Contacts', key: 'name' },
    { label: 'Count', key: 'count', type: 'number' },
  ]
  const conversationAgentTable = [
    { label: 'Agent Name', key: 'name' },
    { label: 'Assigned', key: 'count', type: 'number' },
    { label: 'Open', key: 'open_count', type: 'number' },
    { label: 'Closed', key: 'closed_count', type: 'number' },
  ]
  const contentContentTable = [
    { label: 'Top Content', key: 'name' },
    { label: 'View', key: 'total_view', type: 'number' },
  ]
  const contentTypeTable = [
    { label: 'Content Type', key: 'name' },
    { label: 'View', key: 'total_view', type: 'number' },
  ]
  const contentChannelTable = [
    { label: 'Content by Channel', key: 'name' },
    { label: 'View', key: 'total_view', type: 'number' },
  ]

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg" style={{ padding: theme.spacing(4) }}>
        <Grid>
          <Grid item sm={6} md={5} lg={4}>
            <Box mt={2} />
            <Box style={{ padding: theme.spacing(2)}}>
              <Typography variant='h4' style={{ color: 'white', fontWeight: 600 }}>
              { moment(new Date()).format('dddd, DD MMM') }
              </Typography>
            </Box>
            <RealTimeDataView dataType='visitor' table='app' />
            <RealTimeDataView dataType='conversation' table='queue' />
            <HistoricalDataView dataType='contact' table='contact' columns={contactContactTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='conversation' table='agent' columns={conversationAgentTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='visitor' table='app' columns={visitorAppTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='visitor' table='city' columns={visitorCityTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='visitor' table='medium' columns={visitorMediumTable} startDate={startDate} endDate={endDate} />
            
            {/*
            <HistoricalDataView dataType='content' table='content_type' columns={contentTypeTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='content' table='channel' columns={contentChannelTable} startDate={startDate} endDate={endDate} />
            <HistoricalDataView dataType='content' table='content' columns={contentContentTable} startDate={startDate} endDate={endDate} />
            */}
          </Grid>
          <Grid item sm={6}>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DashboardController)
  )
)
