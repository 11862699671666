import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const realTimeDataInitial = {
  isFetching: false,
  lastFetched: null,
  lastError: null,
  data: { }
}

const historicalDataInitial = {
  isFetching: false,
  lastFetched: null,
  lastError: null,
  startDate: null,
  endDate: null,
  data: { }
}

// Actions

const ACTION = {
  requestRealTimeData: "requestRealTimeData",
  receiveRealTimeData: "receiveRealTimeData",
  requestHistoricalData: "requestHistoricalData",
  receiveHistoricalData: "receiveHistoricalData",
}

export const getRealTimeData = (state, dataType) => {
  if (dataType in state.service.dashboard.realTimeData)
    return state.service.dashboard.realTimeData[dataType]
  
  return realTimeDataInitial
}

export const getHistoricalData = (state, dataType, startDate, endDate) => {
  const key = getHistoricalDataKey(dataType, startDate, endDate)

  if (key in state.service.dashboard.historicalData)
    return state.service.dashboard.historicalData[key]
  
  return historicalDataInitial
}

export const getHistoricalDataKey = (dataType, startDate, endDate) => {
  return `${dataType}-${startDate}-${endDate}`
}

// Async action creator

export const fetchRealTimeData = (dataType, refresh=false) => {
  return (dispatch, getState) => {
    let res = getRealTimeData(getState(), dataType)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return

    let url = process.env.REACT_APP_GOAPP_API_URL
    if (dataType == 'visitor')
      url += "/visitor/service/visitor/realtime_data/"
    else if (dataType == "conversation")
      url += "/conversation/service/conversation/realtime_data/"
    else
      return

    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestRealTimeData(dataType)); 
    return fetch(url, { 
          headers: { 
            'X-Service-Key': service_key,
            ...authorization
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveRealTimeData(dataType, json)))
      .catch(error => dispatch(receiveRealTimeData(dataType, null, error)));
  }
}


export const fetchHistoricalData = (dataType, startDate, endDate, refresh=false) => {
  return (dispatch, getState) => {
    let res = getHistoricalData(getState(), dataType, startDate, endDate)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return

    let url = process.env.REACT_APP_GOAPP_API_URL
    if (dataType == 'visitor')
      url += "/visitor/service/visitor/historical_data/"
    else if (dataType == "conversation")
      url += "/conversation/service/conversation/historical_data/"
    else if (dataType == "contact")
      url += "/contact/service/contact/acquisition_data/"
    else if (dataType == "content")
      url += "/content/service/content/summary_data/"
    else
      return

    url += `?start_date=${startDate}&end_date=${endDate}`

    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestHistoricalData(dataType, startDate, endDate)); 
    return fetch(url, { 
          headers: { 
            'X-Service-Key': service_key,
            ...authorization
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveHistoricalData(dataType, startDate, endDate, json)))
      .catch(error => dispatch(receiveHistoricalData(dataType, startDate, endDate, null, error)));
  }
}

// Synchronous Action Creators for Business Info

export const requestRealTimeData = (dataType) => ({
  type: ACTION.requestRealTimeData,
  dataType,
});

export const receiveRealTimeData = (dataType, json, error) => {
  return {
    type: ACTION.receiveRealTimeData,
    dataType,
    result: json,
    error,
    receivedAt: Date.now()
  };
}

export const requestHistoricalData = (dataType, startDate, endDate) => ({
  type: ACTION.requestHistoricalData,
  dataType,
  startDate,
  endDate
})

export const receiveHistoricalData = (dataType, startDate, endDate, json, error) => {
  return {
    type: ACTION.receiveHistoricalData,
    dataType,
    startDate,
    endDate,
    result: json,
    error,
    receivedAt: Date.now()
  };
}

// Reducers for Business

const realTimeDataReducer = createReducer(realTimeDataInitial, {
  [ACTION.requestRealTimeData]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveRealTimeData]: (state, action) => {
      if (action.error) {
        alert(action.error)
        return {
          ...state,
          isFetching: false,
          lastError: action.error,
        }
      }

      // alert("Data:" + JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
})

const historicalDataReducer = createReducer(historicalDataInitial, {
  [ACTION.requestHistoricalData]: (state, action) => {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        isFetching: true
      }
    },
  [ACTION.receiveHistoricalData]: (state, action) => {
      if (action.error) {
        alert(action.error)
        return {
          ...state,
          isFetching: false,
          lastError: action.error,
        }
      }

      // alert("Data:" + JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
})

function realTimeDataByKeyReducer(state={}, action) {
  if (action.type == ACTION.requestRealTimeData ||
    action.type == ACTION.receiveRealTimeData
    ) {
    const key = action.dataType
    return {
      ...state,
      [key]: realTimeDataReducer(state[key], action)
    }
  }

  return state
}

function historicalDataByKeyReducer(state={}, action) {
  if (action.type == ACTION.requestHistoricalData ||
    action.type == ACTION.receiveHistoricalData
    ) {
    const key = getHistoricalDataKey(action.dataType, action.startDate, action.endDate)
    return {
      ...state,
      [key]: historicalDataReducer(state[key], action)
    }
  }

  return state
}

const dashboardReducer = combineReducers({
  realTimeData: realTimeDataByKeyReducer,
  historicalData: historicalDataByKeyReducer
})

export default dashboardReducer
