import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSetting, updateSetting, fetchSetting } from '../data/reducers/setting'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { SectionListView, MoreSectionItem, SectionItem, SectionCard } from './SettingView'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})

const QueueSettingCard = ({ theme, classes, data, updateSetting }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)
  
  const fields = [
    { fieldName: 'name', type: 'text', label: 'Name' },
    { fieldName: 'auto_close_timeout', type: 'dmhduration', label: 'Auto close conversations' },
    { fieldName: 'uid', type: 'text', label: 'Queue ID', readOnly: true },    
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])

  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting(editData)
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }  
  
  return (
    <SectionCard theme={theme} classes={classes}
      title={data.name}
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          readOnly={item.readOnly}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      {/*
      <MoreSectionItem hasMore={!showAllField}
        onClick={() => setShowAllField(!showAllField)}
      />
      */}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
    </SectionCard>
  )
}

const QueuesSettingView = ({ theme, classes, fetchQueuesSetting, queuesSetting, updateQueuesSetting }) => {

  useEffect(() => {
    fetchQueuesSetting()
  }, [])

  if (!queuesSetting.lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        { queuesSetting.isFetching &&
          <CircularProgress size='1.5rem' disableShrink={true} />
        }
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <SectionListView title="Queue">        
        { queuesSetting.data.map(queue =>
          <QueueSettingCard theme={theme} classes={classes} data={queue}
            updateSetting={data => updateQueuesSetting({uid: queue.uid, ...data})}
          />
        )}
      </SectionListView>
    </div>    
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    queuesSetting: getSetting(state, 'queues'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchQueuesSetting: () => { dispatch(fetchSetting('queues', true)) },
    updateQueuesSetting: (data) => { dispatch(updateSetting('queues', data)) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QueuesSettingView)
)