import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure
const settingInitial = {
  isFetching: false,
  isUpdating: false,
  lastError: null,
  lastFetched: null,
  data: { }
}

// Actions
const ACTION = {
  beginFetchSetting: "beginFetchSetting",
  completeFetchSetting: "completeFetchSetting",
  beginUpdateSetting: "beginUpdateSetting",
  completeUpdateSetting: "completeUpdateSetting",
}

// Async action creator

export const getSetting = (state, key) => {
  if (key in state.service.setting) 
    return state.service.setting[key]
  
  return settingInitial
}

export const fetchSetting = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getSetting(getState(), key)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return
      
    let url = process.env.REACT_APP_GOAPP_API_URL
    
    if (key == 'business')   
      url += "/directory/service/business/"
    else if (key == 'applications')
      url += "/directory/service/app/"
    else if (key == 'queues')
      url += "/conversation/service/queue/"
    else if (key == 'operators')
      url += "/operator/service/operator/"
    else if (key == 'users')
      url += "/directory/service/user/staffs/"
    else if (key == 'channels')
      url += "/directory/service/channel/"
    else
      return
      
    //alert(url)

    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(beginFetchSetting(key)); 
    return fetch(url, { 
          headers: { 
            'X-Service-Key': service_key,
            ...authorization
          },
        })
      .then(response => response.json())
      .then(json => dispatch(completeFetchSetting(key, json, null)))
      .catch(error => dispatch(completeFetchSetting(key, null, error)));
  }
}

export const callCustomAPI = (apiFunc) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (!user)
      return
    
    let service_key = user.userInfo.business_uid
    let apiUrl = process.env.REACT_APP_GOAPP_API_URL    
    let authHeader = {
      'X-Service-Key': service_key,      
    }
    
    return apiFunc(apiUrl, authHeader)    
  }
}

export const updateSetting = (key, data, refresh=false) => {
  return (dispatch, getState) => {
    let res = getSetting(getState(), key)
    if (res.isUpdating)
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return
      
    //alert(JSON.stringify(data, null, 2))
    
    let url = process.env.REACT_APP_GOAPP_API_URL
    
    if (key == 'business')
      url += `/directory/service/business/${data.uid}/`
    else if (key == 'channels')
      url += `/directory/service/channel/${data.uid}/`
    else if (key == 'queues')
      url += `/conversation/service/queue/${data.uid}/`
    else if (key == 'applications')
      url += `/directory/service/app/${data.uid}/`
    else if (key == 'users')
      url += `/directory/service/user/${data.uid}/`      
    else if (key == 'operators')
      url += `/operator/service/operator/${data.uid}/`      
    else
      return
      
    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(beginUpdateSetting(key)); 
    return fetch(url, { 
          method: 'PUT',
          cache: 'no-cache',
          headers: { 
            'X-Service-Key': service_key, 
            'Content-Type': 'application/json',
            ...authorization
          },
          body: JSON.stringify(data)     
        })
      .then(response => response.json())
      .then(json => dispatch(completeUpdateSetting(key, json, null)))
      .catch(error => dispatch(completeUpdateSetting(key, null, error)));
  }
}

export const addSetting = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().service.user
    if (!user)
      return
      
    //alert(JSON.stringify(data, null, 2))
    
    let url = process.env.REACT_APP_GOAPP_API_URL
    
    if (key == 'users')
      url += `/directory/service/user/`
    else
      return
      
    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

      return fetch(url, { 
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'X-Service-Key': service_key, 
            'Content-Type': 'application/json',
            ...authorization
          },
          body: JSON.stringify(data)     
        })
      .then(response => response.json())
      .then(json => {
        dispatch(completeUpdateSetting(key, json, null))
        onComplete(json, null)
      })
      .catch(error => {
        dispatch(completeUpdateSetting(key, null, error))
        onComplete(null, error)
      })
  }
}

// Synchronous Action Creators

export const beginFetchSetting = (key) => ({
  type: ACTION.beginFetchSetting,
  key,
})

export const completeFetchSetting = (key, json, error) => {
  // alert(JSON.stringify(json, null, 2))
  
  return {
    type: ACTION.completeFetchSetting,
    key,
    result: json,
    receivedAt: Date.now(),
    error
  };
}

export const beginUpdateSetting = (key) => ({
  type: ACTION.beginUpdateSetting,
  key,
})

export const completeUpdateSetting = (key, json, error) => ({
  type: ACTION.completeUpdateSetting,
  key,
  result: json,
  receivedAt: Date.now(),
  error
})

const settingReducer = createReducer(settingInitial, {
  [ACTION.beginFetchSetting]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.completeFetchSetting]: (state, action) => {
      if (action.error) {
        alert(action.error)
        return {
          ...state,
          isFetching: false,
          lastError: action.error
        }        
      }

//      alert(JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        lastError: action.error,
        data: action.result
      }        
    },
  [ACTION.beginUpdateSetting]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.completeUpdateSetting]: (state, action) => {
      if (action.error) {
        alert(action.error)
        return {
          ...state,
          isUpdating: false,
          lastError: action.error
        }        
      }
      
      let newData
      if (state.data.find(d => d.uid == action.result.uid))
        newData = state.data.map(d => d.uid == action.result.uid ? action.result : d)
      else
        newData = [...state.data, action.result]      
      
      return {
        ...state,
        isUpdating: false,
        lastFetched: action.receivedAt,
        lastError: action.error,
        data: newData
      }        
    },    
})

function settingByKeyReducer(state={}, action) {
  if (action.type == ACTION.beginFetchSetting ||
      action.type == ACTION.completeFetchSetting ||
      action.type == ACTION.beginUpdateSetting ||
      action.type == ACTION.completeUpdateSetting
    ) {
    const key = action.key
    return {
      ...state,
      [key]: settingReducer(state[key], action)
    }
  }

  return state
}


export default settingByKeyReducer
