import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getContactList, fetchContactList } from '../data/reducers/contact'

import SplitListController from './SplitListController'
import { NavigationContext } from './NavigationController'

import ContactView from '../views/ContactView'
import FilterBar from '../views/FilterBar'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import PeopleIcon from '@material-ui/icons/People'
import CompanyIcon from '@material-ui/icons/Business'

const styles = theme => ({
  root: {
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },    

})

const ContactItem = ({ theme, classes, business, item, selected, onClick }) => {

  let date = 0
  if (item.last_activity_time)
    date = moment(item.last_activity_time)
  if (item.last_seen && moment(item.last_seen) > date)
    date = moment(item.last_seen)

  return (
    <ListItem alignItems="flex-start" onClick={() => onClick(item)}
      style={{ cursor: 'default' }}
      >
      { selected &&
      <div style={{
        width: '4px',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main
        }}/>
      }

      {/*
      <ListItemAvatar>
        <Avatar alt="You" src="" />
      </ListItemAvatar>
      */}

      <ListItemText
        primary={ 
          <>
            <Box display='flex' alignItems='baseline'>
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }} noWrap={true}>
                { item.first_name } { item.last_name }
                { !(item.first_name || item.last_name) && "New Contact" }
                </Typography>
              </Box>
              <Box flexShrink={0}>
                <Typography
                  variant="body2"
                  style={{ color: 'grey' }}
                  noWrap={true}
                >
                  { date > 0 && moment(date).fromNow(true) }
                </Typography>          
              </Box>
            </Box>
          </>
        }
      />
    </ListItem>
  )  
}

const ContactsController = ({ history, theme, match, classes, user, business, ...props }) => {

  const { lastFetched, fetchContactList, isFetching, data } = props
  
  const navigationContext = useContext(NavigationContext)

  let contactKey = -1
  if (match && match.params.contactKey)
    contactKey = match.params.contactKey

  let listKey = 'all'
  if (match && match.params.listKey)
    listKey = match.params.listKey

  const listTypes = [
    { key: 'all', title: 'All Contacts', icon: PeopleIcon },
    { key: 'active', title: 'Active', icon: PeopleIcon },
    { key: 'slipping_out', title: 'Slipping Out', icon: PeopleIcon },
    { key: 'companies', title: 'Companies', icon: CompanyIcon },    
  ]

  const filterContacts = (contacts, filterKey) => {
    if (!contacts)
      return []

    return contacts.filter(c => {
      if (filterKey == 'all')
        return true
      return false
    })
  }

  const visibleListTypes = listTypes.map(l => {
    return {
      ...l,
      count: filterContacts(data.records, l.key).length
    }
  })

  const contacts = lastFetched && filterContacts(data.records, listKey)
    .sort((a, b) =>
      0 //moment(b.data.last_active).toDate().getTime() - moment(a.data.last_active).toDate().getTime()
    ) || []


  useEffect(() => {
    fetchContactList()

    return () => {
    }        
  }, [])

  const showContactDetail = (key) => {
    history.push("/contacts/view/" + key)
  }
  
  const hideContactDetail = () => {
    history.push("/contacts")
  }
  
  const renderListItem = (itemPosition) => {
    if (!lastFetched)
        return null

    let item = contacts[itemPosition]

    return (
      <ContactItem theme={theme} classes={classes} business={business} item={item}
        selected={contactKey == item.uid}
        onClick={()=>showContactDetail(item.uid)}
      />
    )
  }

  return (
    <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <FilterBar
        leftItems={(
          <>
            { visibleListTypes.map((item, index) =>
              <Box 
                className={item.key == listKey ? classes.menuItemSelected : classes.menuItemDefault}>
                <Button style={{ color: item.key == listKey ? 'black' : 'gray'}}
                  className={item.key == listKey ? classes.filterTitleButtonBold : classes.filterTitleButton}
                  component={Link}
                  to={`/contacts/${item.key}`}
                  >
                  { item.icon && React.createElement(item.icon, { color: 'inherit' }) }
                  { item.title }
                  { item.count > 0 && `(${item.count})`}
                </Button>
              </Box>
              )}
          </>
        )}      
      />
      <div style={{ 
          flexGrow: 1, 
          height: '50%' // Without this, our split list won't stretch 100% height. It's weird
          }}>      
        <SplitListController
          topInset={navigationContext.contentInset.top + 40}
          itemCount={contacts.length}
          renderListItem={renderListItem}
          fixedContentPane={true}
          >
          { contactKey > 0 &&
            <ContactView
              contactKey={contactKey}
            />
          }
        </SplitListController>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...getContactList(state),
    user: state.user,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    fetchContactList: () => {
      dispatch(fetchContactList(true))
    }
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ContactsController)
  )
)
