import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import { default as SlickSlider } from 'react-slick'
//import "slick-carousel/slick/slick.css"
//import "slick-carousel/slick/slick-theme.css"

import Card from './Card'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const styles = theme => ({
  root: {
  },
  sliderPrevButton:{
    top: '50%',
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -50%)',
    left: 0, 
    zIndex: 1, 
    width: '32px', 
    height: '32px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%'
  },
  sliderNextButton:{
    top: '50%',
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -50%)',
    right: 0, 
    zIndex: 1, 
    width: '32px', 
    height: '32px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%'
    
  },
  iconChevron:{
    fontSize: '2rem',
    color: 'white',
  },  
})

const CardList = ({ classes, theme, content, openLink, sendMessage }) => {

  function onClick(button) {
    if (button.button_type && button.button_type == 'callback') {
      let data = {
        ...button
        }
      // alert(JSON.stringify(data, null, 2))
      sendMessage(JSON.stringify(data))
    }
    else if (button.link_to)  
      openLink(button.link_to)
  }

  function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box 
        className={ classes.sliderPrevButton }
        onClick={onClick}>
        <ChevronLeftIcon className={classes.iconChevron}/>
      </Box>
    )
  }
  
  function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={ classes.sliderNextButton }
        onClick={onClick}>
        <ChevronRightIcon className={classes.iconChevron}/>
      </Box>
    )
  }

  return (
    <Box mt={2} mb={2}>        
      <SlickSlider
        className="center"
        arrows={true}
        slidesToShow={1.3}
        infinite={false}
        prevArrow={ <SliderPrevArrow/> }
        nextArrow={ <SliderNextArrow/> }
      >
        { content.card_list.map((card, index) => (
        <div>
          <Box style={{ padding: '0px 8px' }}>
            <Card 
              content={card} 
              sendMessage={sendMessage}
              openLink={openLink}
            />
          </Box>
        </div>
          ))
        }
      </SlickSlider>
   </Box>
  )
}

export default withStyles(styles, { withTheme: true })(
  CardList
  )
