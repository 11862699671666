import { renderToString } from 'react-dom/server'
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getConversation, fetchConversation, fetchMessages, sendMessage, markReadAll, startTyping, WSSTATE, getConnectionState } from '../../data/reducers/conversation'
import { getBusinessInfo, fetchBusinessInfo } from '../../data/reducers/business'
import { getUser } from '../../data/reducers/user'
import { wsSend } from '../../middleware/conversation'

import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import moment from 'moment'
import classNames from 'classnames'

import MessageList, { parseBody } from './MessageList'
import ComposeBar from './ComposeBar'
import HeaderView from './HeaderView'

import Divider from '@material-ui/core/Divider'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  header: {
    zIndex: 1300,
    position: 'sticky',
    top: '0',
  },
  footer: {
    display: 'block',
    position: 'sticky',
    bottom: '0',
  },
  chatInfo: {
    color: '#999',
  },
  inline: {
    display: 'inline',
  },
  displayNone: {
    display: 'none'
  },

  muted: {
    color: '#999'
  },
})

const ChatView = ({ 
    height=100, // Set to 100 as it could be undefined on load
    theme, classes, history, business, user, conversationKey, conversation, fetchMessages, sendMessage, markReadAll, startTyping, onHide, connectionState, headerComponent=HeaderView, renderHeader }) => {
  
  useEffect(() => {
    let timeoutID = null
    if (conversation && conversation.data.new_count > 0) {
      timeoutID = setTimeout(() => {
        markReadAll()
      }, 50)    
    }
    
    return () => {
      if (timeoutID)
        clearTimeout(timeoutID)    
    }
  }, [conversation.data.new_count])
  
  const [firstFetch, setFirstFetch] = useState(true)
  
  useEffect(() => {
    // Refetch on first open or when connected
    if (firstFetch || connectionState == WSSTATE.Connected) {
      fetchMessages()
      if (firstFetch)
        setFirstFetch(false)
    }
  }, [connectionState, conversationKey])
  
  const checkOnlineAndSendMessage = (message, files=null) => {
    if (connectionState != WSSTATE.Connected) {
      alert("Can not send message because you are not online. Please check your internet connection and try again!")
      return
    }
    
    sendMessage(message, files)
  }
  
  const openLink = (linkTo) => {
    if (linkTo.startsWith("mailto:")) {
      window.location.href = linkTo
      return
    }

    let link = ""
    if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo

    if (link == "/home")
      link = "/"

    history.push(link)

    onHide()
  }

  let typing_at = null
  conversation.data.parties && conversation.data.parties.forEach(p => {
    if (p.agent && p.agent.uid == user.userInfo.uid && p.typing_at)
      typing_at = moment(p.typing_at).toDate()
  })

  let composeComponent = null
  let defaultValue = null
  
  if (conversation.data.accept_reply) {
    let disableSendMessage = false
    if (conversation.messages.length) {
      let lastMessage = conversation.messages[conversation.messages.length-1]
      let content = parseBody(lastMessage.body)

      defaultValue = content.default || ""

      if (!user.userInfo.is_staff && content['disable_send_message'])
        disableSendMessage = true
    }
    
    if (!composeComponent)
      composeComponent = (
        <ComposeBar sendMessage={checkOnlineAndSendMessage} defaultValue={defaultValue} 
          disabled={disableSendMessage}
          onChange={() => {
            if (!typing_at || (new Date().getTime() - typing_at.getTime()) >= 5000)
              startTyping()
          }}
        />
      )
  }

  let header = null
  if (conversation) {
    if (renderHeader)
      header = renderHeader(conversation)
    else if (headerComponent)
      header = React.createElement(headerComponent, {
        business, conversation, connectionState
        })
  }

  return (
    <div className={classes.root}
      style={{
        height: height,
        position: 'relative'
      }}
      >
      { header &&
      <div className={classes.header}
        style={{
          position: 'absolute',
          top: 0,
          width: '100%'
        }}
        >
        { header }
      </div>
      }

      <MessageList
        business={business}
        user={user}
        conversation={conversation}
        sendMessage={checkOnlineAndSendMessage}
        openLink={openLink}
        containerHeight={height - (composeComponent ? 70 : 0)}
        />
      <Divider />
      <div className={classes.footer}
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%'
        }}
        >
      { composeComponent }
      </div>
    </div>
    )
}

function conversationKeyFromProps(props) {
  let key=0
  if (props.conversationKey)
    key = props.conversationKey
  else if (props.match)
    key = props.match.params.key
  return key
}

const mapStateToProps = (state, ownProps) => {
  let key = conversationKeyFromProps(ownProps)
  
  return {    
    conversation: getConversation(state, key),
    business: getBusinessInfo(state, '0').data,
    user: getUser(state),
    connectionState: getConnectionState(state)
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let key = conversationKeyFromProps(ownProps)

  return {
    wsStart: () => {
      dispatch(wsSend('start', null))
    },
    fetchMessages: () => {
      dispatch(fetchMessages(key, true, false))
    },
    sendMessage: (message, files=null) => {
      dispatch(sendMessage(key, message, files))
    },
    startTyping: () => {
      dispatch(startTyping(key))
    },
    markReadAll: () => {
      dispatch(markReadAll(key))
    },
  }
}

export default withResizeDetector(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatView)))
