import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTheme } from "@material-ui/styles"

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#000'
  }
}));

export const validateInput = (value, required, validation) => {
  let val = value    
  if (!val || val == undefined)
    val = ""
  else
    val = val.trim()
  if (required && val.length == 0)
    return false
  if (val.length > 0) {
    if (validation === 'email') {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(val).toLowerCase()))
        return false
    }
  }
  
  return true
}


export const MoreSectionItem = ({ hasMore, onClick }) => {
  const classes = useStyles()
  
  return (
    <ListItem button 
      style={{ padding: 0, display: 'flex', justifyContent: 'center'}}
      onClick={onClick}
      >
      { hasMore ? 
        <ArrowDropDownIcon color='grey' /> 
      : 
        <ArrowDropUpIcon color='grey' /> 
      }
    </ListItem>
  )
}

export const ItemValue = ({ item, value }) => {
  if (item.type == 'image') {
    let containerSize = item.containerSize || { width: 100, height: 100 }
    return (                     
      <Box style={{ 
          width: containerSize.width,
          height: containerSize.height,
          border: '1px solid #eee',
        }}>
        <img style={{
            maxHeight: '100%',
            maxWidth: '100%'
          }}
          src={ value.image_url}
        />
      </Box>
    )
  }

  if (value === undefined || value === null)
    return null

  if (item.type == 'option' && item.options) {
    const opt = item.options.find(o => o.value == value)
    if (opt)
      return opt.text    
  }

  return value
}

export const DHMDurationInput = ({ value, onChange, defaultMode='minute' }) => {

  const theme = useTheme()
  const [mode, setMode] = useState(null)

  let currentMode = mode
  if (!mode) {
    if (value % (60 * 60 * 24) == 0)
      currentMode = "day"
    else if (value % (60* 60) == 0)
      currentMode = "hour"
    else
      currentMode = "minute"
    setMode(currentMode)
  }

  let currentValue = value || 0
  let maxValue
  if (currentMode == "day") {
    currentValue = Math.ceil(parseFloat(currentValue) / (60 * 60 * 24))
    maxValue = 30
  }
  else if (currentMode == "hour") {
    currentValue = Math.ceil(parseFloat(currentValue) / (60 * 60))
    maxValue = 48
  }
  else {
    currentValue = Math.ceil(parseFloat(currentValue) / 60)
    maxValue = 300
  }

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <Typography>
          { currentValue }
        </Typography>
        <Button color="primary" style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize }}
          onClick={() => {
            if (mode == 'minute') {
              setMode("hour")
              onChange(4*60*60)
            }
            else if (mode == 'hour') {
              setMode("day")
              onChange(7*24*60*60)
            }
            else {
              setMode("minute")
              onChange(30*60)
            }
          }}
          >
          { currentMode }
        </Button>
        <Box ml={4}/>
      </Box>
      <Slider 
        style={{ maxWidth: 200, marginLeft: '1pt' }}
        value={currentValue} 
        max={maxValue} 
        onChange={(event, value) => {
          if (mode == 'day')
            onChange(value * 24 * 60 * 60)
          else if (mode == 'hour')
            onChange(value * 60 * 60)
          else
            onChange(value * 60)
        }}
      />      
    </Box>
  )

}

export const SectionItem = ({ item, isEditing, value, isValid=true, onChange, setEditing, setIsValid, readOnly }) => {
  const inputRef = useRef()
  
  const classes = useStyles()
  const theme = useTheme()

  const [optionAnchorEl, setOptionAnchorEl] = useState(null)
  
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.focus()
  }, [inputRef, isEditing])

  let itemComponent

  if (item.renderValue) {
    return (    
      <ListItem alignItems="flex-start" className={classes.listItemView}
        style={{ display: 'relative',  paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        >
        <ListItemText
          primary={ item.renderValue(item, value) }
          secondary={ 
            <>
              { item.label  }
            </>
          }
        />   
      </ListItem>
    )
  }
  else if (item.type == 'option') {
    return (    
      <ListItem button={false} alignItems="flex-start" className={classes.listItemView}
        style={{ cursor: 'pointer', display: 'relative',  paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        onClick={(event) => {
          if (readOnly)
            return          
          if (!optionAnchorEl) {
            setEditing(true)
            setOptionAnchorEl(event.currentTarget)
          }
        }}
        >
        <ListItemText
          primary={
            <ItemValue item={item} value={value} /> 
          }
          secondary={ 
            <>
              { item.label  }
              { !isValid &&
                <span style={{ color: 'red' }}>
                  &nbsp; Not valid
                </span>
              }
            </>
          }
        />

        <Menu
          id={optionAnchorEl ? 'status-menu-popover' : undefined}
          open={Boolean(optionAnchorEl) }
          anchorEl={optionAnchorEl}
          getContentAnchorEl={null}
          onClose={() => setOptionAnchorEl(false)}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}        
          >
          { item.options.map(opt =>
            <MenuItem onClick={() => {
                onChange(opt.value)
                setOptionAnchorEl(false)
              }}>
              { opt.text }
            </MenuItem>
          )}
        </Menu>        
      </ListItem>
    )
  }
  else if (item.type == 'switch') {
    return (    
      <ListItem alignItems="flex-start" className={classes.listItemView}
        style={{ display: 'relative',  paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        >
        <ListItemText
          primary={
            <>
              <Switch
                checked={Boolean(value)}
                name={item.fieldName}
                color='primary'
                readOnly={readOnly}
                onChange={(e) => onChange(e.target.checked)}
                />
            </>
          }
          secondary={item.label}
        />   
      </ListItem>
    )
  }
  else if (item.type == 'dmhduration') {
    return (    
      <ListItem alignItems="flex-start" className={classes.listItemView}
        style={{ display: 'relative',  paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        >
        <ListItemText
          primary={
            <DHMDurationInput
              readOnly={readOnly}
              value={value}
              onChange={onChange}
              />
          }
          secondary={item.label}
        />   
      </ListItem>
    )
  }    
  else if (item.type == 'image') {
    return (
      <ListItem button={false} alignItems="flex-start" className={classes.listItemView}
        style={{ cursor: 'pointer', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        onClick={() => {
          if (readOnly)
            return          
          inputRef.current.click()
        }}
        >
        <input
          ref={inputRef}
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file"
          onChange={null}
          type="file"
        />

        <ListItemText
          primary={ 
            <ItemValue item={item} value={value} /> 
          }
          secondary={ 
            <>
              { item.label  }
              { !isValid &&
                <span style={{ color: 'red' }}>
                  &nbsp; Not valid
                </span>
              }
            </>
          }
        />   
      </ListItem>   
    )
  }
  else {
    return (
      <ListItem button={!isEditing} alignItems="flex-start" className={classes.listItemView}
        style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
        onClick={() => {
          if (readOnly)
            return
          if (item.type == 'image')
            inputRef.current.click()
          else
            setEditing(true)
        }}
        >
        { item.type == 'image' &&
          <input
            ref={inputRef}
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={null}
            type="file"
          />
        }

        <ListItemText
          primary={ 
            isEditing ?
              <input
                ref={inputRef}
                style={{
                  border: 'none',
                  fontSize: '1rem',
                  width: '100%',
                  backgroundColor: 'transparent',
                  padding: '8pt 8pt',
                  margin: '0 -8pt'
                }}
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                  if (setIsValid) {
                    const valid = validateInput(e.target.value, item.required, item.validation)
                    if (valid != isValid)
                      setIsValid(valid)
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13)
                    setEditing(false)
                  else if (e.keyCode == 27) {
                    setEditing(false)
                    e.stopPropagation()
                  }
                }}              
              />
            :          
              <ItemValue item={item} value={value} /> 
          }
          secondary={ 
            <>
              { item.label  }
              { !isValid &&
                <span style={{ color: 'red' }}>
                  &nbsp; Not valid
                </span>
              }
            </>
          }
        />   
      </ListItem>   
    )
  }
}

export const SectionCard = ({ title, icon, detail, children, defaultExpanded, contentStyle }) => {

  const classes = useStyles()
  const theme = useTheme()
  
  return (
    <div className={classes.sectionCardRoot}
      style={{ borderTop: 'solid 1px #eee'}}
      >
      <ExpansionPanel 
        defaultExpanded={defaultExpanded}
        elevation={0}
        square={1}
        >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Box display='flex' style={{ width: '100%' }} alignItems='center'>
            { false && icon &&
              <Box mr={1}>
                { React.createElement(icon, {
                    style: { color: '#aaa', fontSize: '1.8rem' }
                  })
                }
              </Box>
            }

            <Box flexGrow={1}>
              <Typography className={classes.sectionCardHeading}>
                { title }
              </Typography>
            </Box>

            { detail }
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            padding: `0 ${theme.spacing(1)}px`, 
            marginTop: '-18px',
            ...(contentStyle || {})
          }}
          >
          <List style={{ width: '100%' }}>
            { children }
          </List>

        </ExpansionPanelDetails>
      </ExpansionPanel>    
    </div>
  )  
}

export const SectionListView = ({ title, actionButtons, children }) => {

  const classes = useStyles()
  const theme = useTheme()
  
  return (
    <div className={classes.sectionListRoot}
      >
      <Box
          display='flex'
          alignItems='center'
          style={{ padding: theme.spacing(3) }}
        >
        <Box flexGrow={1}>
          <Typography variant='h5' style={{ fontWeight: 500 }}>
            { title }
          </Typography>
        </Box>
        { actionButtons }
      </Box>
      <Box>
        { children }
      </Box>
    </div>
  )  
}
