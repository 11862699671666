import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getRealTimeData, fetchRealTimeData } from '../data/reducers/dashboard'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography  from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),    
    backgroundColor: 'white'
  },
})

const RealTimeDataView = ({ theme, classes, dataType, table, fetchRealTimeData, data, ...props }) => {

//  const [refreshTimer, setRefreshTimer] = useState()
  useEffect(() => {
    fetchRealTimeData()

    const refreshTimer = setInterval(fetchRealTimeData, 3000)

    return () => {
      clearInterval(refreshTimer)
    }

  }, [dataType])

  const rows = data.lastFetched && data.data[table]

  return (
    <Paper className={classes.root}>
      { !data.lastFetched && data.isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
            <CircularProgress size='1.5rem' disableShrink={true} />        
        </Box>
      }
      { data.lastFetched &&
        <Box display='flex' justifyContent='flex-start'>
          { rows.map(item =>
            <Box display='flex' flexDirection='column' alignItems='flex-start' 
              flexGrow={1}
              style={{
                // width: '100pt',
                position: 'relative'
              }}
              >
              <Box style={{
                  position: "absolute",
                  top: theme.spacing(1),
                  left: theme.spacing(2),
                }}>
                <Typography variant='body2' 
                  style={{ 
                    fontSize: '.9rem', 
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all',
                    // color: 'grey'
                    fontWeight: 500
                  }}
                  >
                  { item.name }
                </Typography>
              </Box>                   
              <Box style={{
                  padding: theme.spacing(3),
                  textAlign: 'center',
                  width: '100%'
                }}>
                <Typography variant='h3'>
                  { item.count }
                </Typography>
              </Box>
         
            </Box>
          )}
        </Box>    
      }
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getRealTimeData(state, ownProps.dataType)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchRealTimeData: () => {
      dispatch(fetchRealTimeData(ownProps.dataType, true))
    }
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RealTimeDataView)
  )
)
