import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const queueInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  isUpdating: false,
  data: { }
}

// Actions
const ACTION = {
  requestQueue: "requestQueue",
  receiveQueue: "receiveQueue",
  errorRequestQueue: "errorRequestQueue",
  updateQueue: "updateQueue",
  receiveAvailability: "receiveAvailability",
  errorUpdateQueue: "errorUpdateQueue",
}

export const getMyAvailability = (state, key='0') => {
  const queue = getQueue(state, key)
  if (queue.lastFetched && queue.data.agents) {
    let user = state.service.user
    return queue.data.agents.find(a => a.user.uid == user.userInfo.uid)
  }
  
  return null
}

export const getQueue = (state, key='0') => {
  if (key in state.service.queue) 
    return state.service.queue[key]
  
  return queueInitial
}

// Async action creator

export const fetchQueue = (key='0', refresh=false) => {
  return (dispatch, getState) => {
    let res = getQueue(getState(), key)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return

    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/queue/" + key + "/"

    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestQueue(key)); 
    return fetch(url, { 
          headers: { 
            'X-Service-Key': service_key,
            ...authorization
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveQueue(key, json)))
      .catch(error => dispatch(errorRequestQueue(key, error)));
  }
}

export const setMyAvailability = (key='0', availability) => {
  return (dispatch, getState) => {
    let res = getQueue(getState(), key)
    if (res.isUpdating)
      return Promise.resolve()

    let user = getState().service.user
    if (!user)
      return

    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/queue/" + key + "/set_availability/"

    let service_key = user.userInfo.business_uid

    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(updateQueue(key)); 
    return fetch(url, { 
          method: 'POST',
          headers: { 
            'X-Service-Key': service_key,
            'Content-Type': 'application/json',            
            ...authorization
          },
          body: JSON.stringify({ availability })
        })
      .then(response => response.json())
      // We'll rely on availability push from server
      .then(json => null) //dispatch(receiveAvailability(key, json)))
      .catch(error => dispatch(errorUpdateQueue(key, error)));
  }
};


// Synchronous Action Creators for Business Info

export const requestQueue = (key) => ({
  type: ACTION.requestQueue,
  key,
});

export const receiveQueue = (key, json) => {
  return {
    type: ACTION.receiveQueue,
    key,
    result: json,
    receivedAt: Date.now()
  };
}

export const errorRequestQueue = (key, error) => ({
  type: ACTION.errorRequestQueue,
  key,
  error
});

export const updateQueue = (key) => ({
  type: ACTION.updateQueue,
  key,
});

export const receiveAvailability = (key, json) => {
  return {
    type: ACTION.receiveAvailability,
    key,
    result: json,
    receivedAt: Date.now()
  };
}

export const errorUpdateQueue = (key, error) => ({
  type: ACTION.errorUpdateQueue,
  key,
  error
});

// Reducers for Business

const queueReducer = createReducer(queueInitial, {
  [ACTION.requestQueue]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveQueue]: (state, action) => {
      // alert("Queue:" + JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestQueue]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.updateQueue]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveAvailability]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        lastFetched: action.receivedAt,
        data: {
          ...state.data,
          agents: state.data.agents && state.data.agents.map(a => {
            if (a.user.uid == action.result.user.uid)
              return action.result
            else
              return a
          })
        }
      }        
    },
  [ACTION.errorUpdateQueue]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    }    
})

function queueByIdUpdater(state, action) {
  let key = action.key
  return {
    ...state,
    [key]: queueReducer(state[key], action)
  }
}

const queueByIdReducer = createReducer({}, {
  [ACTION.requestQueue]: queueByIdUpdater,
  [ACTION.receiveQueue]: queueByIdUpdater,
  [ACTION.errorRequestQueue]: queueByIdUpdater,
  [ACTION.updateQueue]: queueByIdUpdater,
  [ACTION.receiveAvailability]: queueByIdUpdater,
  [ACTION.errorUpdateQueue]: queueByIdUpdater,
})

export default queueByIdReducer
