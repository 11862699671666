import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'

import { getQueue, fetchQueue } from '../data/reducers/queue'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import StatusIcon from '@material-ui/icons/FiberManualRecord'
import AgentIcon from '@material-ui/icons/Person'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

const styles = theme => ({
  root: {
  },
})

const AgentItem = ({ theme, classes, agent }) => {
  let status = "Offline"
  let color = "#aaa"
  if (agent.is_online && agent.availability == 'available') {
    status = "Available"
    color = "green"
  }
  else if (agent.is_online) {
    status = "Not Available"
    color = "#cd853f"
  }


  return (
    <ListItem button alignItems="flex-start" className={classes.listItemView}
      style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
      >
      <ListItemText
        primary={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <StatusIcon style={{ color }} fontSize='small' />
            </Box>
            <Box flexGrow={1}>
              <Typography variant='body1'
                style={{ color: agent.is_online ? "auto" : "grey" }}
                >
                { agent.user.first_name }&nbsp;{agent.user.last_name}
              </Typography>
              <Typography variant='body2' style={{ color: 'grey' }}>
                { status }
              </Typography>
            </Box>
          </Box>
        }
      />
    </ListItem>
  )
}

const AgentList = ({ theme, classes, agents }) => {

  const orderedList = agents.sort((a, b) => {
    if (a.is_online && !b.is_online)
      return -1
    else if (b.is_online && !a.is_online)
      return 1
    else
      return a.user.first_name.localeCompare(b.user.first_name)
  })

  return (
    <List>
      { orderedList.map(a =>
        <AgentItem
          theme={theme}
          classes={classes}
          agent={a}
        />
      )}
    </List>
  )
}

const QueueView = ({ theme, classes, queue }) => {

  return (
    <div className={classes.root}>
      <Box style={{
          padding: theme.spacing(2),
          fontSize: '1rem',
          fontWeight: '800',
          textAlign: 'center'
        }}>
        { queue.data.name }
      </Box>
      <AgentList
        theme={theme}
        classes={classes}
        agents={queue.data.agents}
        />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    queue: getQueue(state, "0"),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchQueue: () => { dispatch(fetchQueue("0")) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QueueView)
)