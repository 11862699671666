import React, { Component, useState, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import { connect } from 'react-redux'
import { Route, Link, withRouter, BrowserRouter as Router, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { getConversationCounts, getConnectionState, WSSTATE } from './data/reducers/conversation'
import { fetchQueue } from './data/reducers/queue'
import { login, logout } from './data/reducers/user'
import { withAppBase } from './AppBase'

import NavigationController from './controllers/NavigationController'
import MainBar from './views/MainBar'
import DashboardController from './controllers/DashboardController'
import ChatController from './controllers/ChatController'
import ContactsController from './controllers/ContactsController'
import PagesController from './controllers/PagesController'
import ContentsController from './controllers/ContentsController'
import SettingsController from './controllers/SettingsController'
import CatalogController from './controllers/CatalogController'
import OrderController from './controllers/OrderController'

import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import useSound from 'use-sound'
//import inboxNotificationSound from './assets/inbox-notification.wav'
//import inboxNotificationSound from './assets/menu-open.mp3'
import inboxNotificationSound from './assets/rising-pops.mp3'

const styles = theme => ({
  root: {
  },
})

const App = ({ params, theme, classes, history, business, login, logout, user, loadingStatus, conversationCounts, fetchQueue, connectionState }) => {

  const backgroundImageList = [
    { 
      imageUrl: "https://images.unsplash.com/photo-1485199433301-8b7102e86995?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
      color: 'rgb(158, 130, 117)',
      color: 'rgb(151, 130, 120)',
      color: 'rgb(191, 170, 160)',
      color: 'rgb(208, 191, 182)',
      color: 'rgb(173, 153, 146)',      
      color: 'rgb(187, 155, 145)',
    },
    { 
      imageUrl: "https://images.unsplash.com/photo-1581252165392-ebc2f68c23de?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
      color: 'rgb(59, 101, 122)',
      color: 'rgb(49, 81, 96)',      
      color: 'rgb(77, 105, 117)',            
    },
    { 
      imageUrl: "https://images.unsplash.com/photo-1586521995568-39abaa0c2311?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
      color: '#aab0aa',
    },
    {
      imageUrl: "https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
      color: 'rgb(167, 147, 137)',      
      color: 'rgb(208, 191, 182)',
      color: 'rgb(174, 144, 130)',      
    }
    //"https://images.unsplash.com/photo-1555605976-8bf855afd253?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1900&h=600&fit=crop&ixid=eyJhcHBfaWQiOjF9"
  ]

  const [backgroundImage, setBackgroundImage] = useState(backgroundImageList[0])
  
  const [messageNotification, setMessageNotification] = useState({ show: false, newCount: 0 })
  const [playInboxNotificationSound] = useSound(inboxNotificationSound)  

  useEffect(() => {
    const index = Math.round(Math.random() * backgroundImageList.length)
    if (index < backgroundImageList.length)
      setBackgroundImage(backgroundImageList[index])
    //setBackgroundImage(backgroundImageList[3])
}, [])

  useEffect(() => {
    if (user.isLoggedIn && connectionState == WSSTATE.Connected)
      fetchQueue()
  }, [user, connectionState])
  
  useEffect(() => {
    let n = 0
    if (conversationCounts.lastFetched) {
      const openGroup = conversationCounts.data.groups.find(g => g.code == "open")
      if (openGroup)
        n = openGroup.not_answered_count + openGroup.new_messages_count
    }
    
    // Clear show timeout to adapt to new changes
    if (messageNotification.showTimeout)
      clearTimeout(messageNotification.showTimeout)
    
    if (n > messageNotification.newCount && n > 0) {
      if (messageNotification.show) {
        // Note: Sound only appear after user click or interact with the page
        playInboxNotificationSound()

        setMessageNotification({ 
          ...messageNotification, newCount: n 
        })
      }
      else {
        // Use timeout in case user is viewing the chat and marking as read
        const timeout = setTimeout(() => {
          setMessageNotification({
            show: true,
            newCount: n
          })
        }, 500)

        // Note: Sound only appear after user click or interact with the page
        playInboxNotificationSound()

        setMessageNotification({
          ...messageNotification,
          newCount: n,
          showTimeout: timeout
        })            
      }        
    }
    else if (n != messageNotification.newCount)
      setMessageNotification({ show: false, newCount: n})
    else
      setMessageNotification({ ...messageNotification, newCount: n})
  }, [conversationCounts])

  if (loadingStatus) {
    return (
      <div className={classes.root}>
        { loadingStatus }
      </div>
      )
  }
  else if (user.authError) {
    return (
      <div className={classes.root}>
        Authentication Failed! Error: { JSON.stringify(user.authError, null, 2) }
      </div>
      )
  }
  else if (!user.isLoggedIn) {
    return (
      <div className={classes.root}>
        You're not authenticated. <a href="#" onClick={login}>Click here to login</a>
      </div>
      )
  }
  else if (!user.isStaff) {
    return (
      <div className={classes.root}>
        You're not authorized. <a href="#" onClick={logout}>Log out</a>
      </div>
      )
  }
  
  const openLink = (url) => {
    history.push(url)
  }

  return (
    <NavigationController
      openLink={openLink}
      backgroundImageUrl={backgroundImage.imageUrl}
      topBarOverlayColor="#ffffff"
      topBarOverlayColor="#cccccc"
      topBarOverlayColor="rgb(174, 193, 168)"
      topBarOverlayColor="rgb(221, 225, 219)"
      topBarOverlayColor="#bbbbbb"      
      topBarOverlayColor="#aab0aa"
      topBarOverlayColor="#aaaaaa"
      topBarOverlayColor={backgroundImage.color}
      topBar={ 
        <MainBar 
          variant='transparent'
          backgroundColor="#f8f8f8"
          backgroundColor="#555555"          
          /> 
      }
      >
      <div className={classes.root}>
        <Switch>
          <Route exact path="/" component={DashboardController}/>          
          <Route exact path="/chat" component={ChatController}/>
          <Route path="/chat/view/:conversationKey" component={ChatController}/>
          <Route path="/chat/:listKey/view/:conversationKey" component={ChatController}/>
          <Route path="/chat/:listKey" component={ChatController}/>
          <Route exact path="/contacts" component={ContactsController}/>
          <Route path="/contacts/view/:contactKey" component={ContactsController}/>
          <Route exact path="/pages" component={PagesController}/>
          <Route path="/pages/edit/:pageKey" component={PagesController}/>

          <Route exact path="/campaign" component={ContentsController}/>
          <Route path="/campaign/:viewType/:modelKey/view/:contentKey" component={ContentsController}/>
          <Route path="/campaign/:viewType" component={ContentsController}/>

          <Route exact path="/settings" component={SettingsController}/>
          <Route path="/settings/:viewType" component={SettingsController}/>          
          <Route path="/catalog" component={CatalogController}/>
          <Route path="/order" component={OrderController}/>          
        </Switch>
      </div>
      <Snackbar
        open={messageNotification.show}
        onClose={() => setMessageNotification({ ...messageNotification, show: false })}
        TransitionComponent={Slide}
        message={`You have ${messageNotification.newCount} new messages`}
        severity="info"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={5000}        
      />
    </NavigationController>      
  )
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)

  return {
    params: urlParams,
    conversationCounts: getConversationCounts(state),
    connectionState: getConnectionState(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let callbackUrl = window.location.href
  
  return {
    login: () => { dispatch(login(callbackUrl)) },
    logout: () => { dispatch(logout(callbackUrl)) },
    fetchQueue: () => { dispatch(fetchQueue("0", true)) },
  }
}

export default withAppBase(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(App)
    )
  ),
)
