import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Route, Link, withRouter, BrowserRouter as Router, Switch } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'

import { NavigationContext } from './NavigationController'

import FilterBar from '../views/FilterBar'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import TableData from '../views/TableData.js'

import PendingIcon from '@material-ui/icons/HourglassEmpty'
import ProcessingIcon from '@material-ui/icons/DirectionsRun'
import CompletedIcon from '@material-ui/icons/Done'

const styles = theme => ({
  root: {
  },
  sectionTitle: {
    fontWeight: 700
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  cardView: {
    // Larger bottom to accomodate shadow
    paddingBottom: theme.spacing(8)
  },
  card: {
  },
  cardTitle: {
    fontWeight: 500,
  },
  counterText: {
    color: 'black',
    fontWeight: 500
  },
  tableView: {

  },
  calendarView: {
  },

})

const CatalogController = ({ history, theme, classes, location }) => {

  const navigationContext = useContext(NavigationContext)

  const viewTypes = [
    { key: 'pending', title: 'Pending', icon: <PendingIcon color='inherit' /> },
    { key: 'processing', title: 'Processing', icon: <ProcessingIcon color='inherit' /> },
    { key: 'completed', title: 'Completed', icon: <CompletedIcon color='inherit' /> },
  ]
  
  const path = location.pathname
  let viewType = null
  if (path.startsWith("/catalog/")) {
    viewType = path.substring("/catalog/".length)
  }
  
  return (
    <div>
      <FilterBar
        leftItems={(
          <>
            { viewTypes.map((item, index) =>
              <Box 
                className={item.key == viewType ? classes.menuItemSelected : classes.menuItemDefault}>
                <Button style={{ color: item.key == viewType ? 'black' : 'gray'}}
                  className={item.key == viewType ? classes.filterTitleButtonBold : classes.filterTitleButton}
                  component={Link}
                  to={`/catalog/${item.key}`}
                  >
                  { item.icon }
                  { item.title }
                </Button>
              </Box>
              )}
          </>
        )}
      />
      <div>
        <Switch>
        </Switch>              
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CatalogController)
  )
)
