import React, { Component, useState, useEffect, useContext } from 'react'

import classNames from 'classnames'

import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import BusinessIcon from '@material-ui/icons/Business'

const styles = theme => ({
  root: {
//    backgroundColor: 'rgb(33, 179, 30)',
    backgroundColor: '#fff',
    width: '100%',
    height: 44,
    borderBottom: 'solid 1px #eee',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  leftMenuBar: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  rightMenuBar: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%'
  },
  titleText: {
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    color: '#000'
  },
  menuSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '1px',
    height: '100%', 
    backgroundColor: '#ccc'
  }
})

const ToolBar = ({ theme, classes, variant, title, menuItems }) => {

  let backgroundStyle = {}
  if (variant == 'transparent')
    backgroundStyle = {
        backgroundColor: 'transparent',
        borderBottom: 'none',
      }

  return (
    <div className={classes.root} style={backgroundStyle}>
      <div className={classes.leftMenuBar}>        
          { React.isValidElement(title) &&
            <>{ title }</>
          }
          { typeof title == "string" &&
            <Box ml={1} mr={3}>
              <Typography color='' className={classes.titleText}>
               { title }
              </Typography>
            </Box>
          }
      </div>
      <div className={classes.rightMenuBar}>
        { menuItems }
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ToolBar)
  )
)
