import { combineReducers } from 'redux'

import userReducer from './reducers/user'
import businessByIdReducer from './reducers/business'
import contentModelReducer from './reducers/content'
import conversationReducer from './reducers/conversation'
import queueReducer from './reducers/queue'
import contactReducer from './reducers/contact'
import dashboardReducer from './reducers/dashboard'
import settingReducer from './reducers/setting'
import productReducer from './reducers/product'

export const serviceReducer = combineReducers({
  business: businessByIdReducer,
  user: userReducer,
  content: contentModelReducer,
  conversation: conversationReducer,
  contact: contactReducer,
  queue: queueReducer,
  dashboard: dashboardReducer,
  setting: settingReducer,  
  product: productReducer
})

