import React, { useState, useEffect, useMemo, useContext } from 'react'
import { connect } from 'react-redux'

import { Page as AlphaPage } from 'alpha-page'
import SplitView from './SplitView'

import { NavigationContext } from '../controllers/NavigationController'
import { getContentRecord, fetchContentRecord, updateContentRecord } from '../data/reducers/content'

import { withStyles } from '@material-ui/core/styles'

import ContentEditor from './ContentEditor'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100vh'
  },
  contentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  pageTitle: {
    fontWeight: 700
  },
  sectionTitle: {
    fontWeight: 700
  },
  contentRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  editorContainer: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    maxWidth: '50em',
    width: '100%'
  },
  toolBar: {
    backgroundColor: '#fff',
    width: '100%',
    height: 44,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'sticky'
  },
  leftMenuBar: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  rightMenuBar: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%'
  },
  infoText: {
    color: '#aaa'
  }
})

const ContentView = ({ theme, classes, ...props }) => {

  const { contentKey, fetchContent, saveContent, isFetching, lastFetched, data } = props
  const { onHide } = props

  const navigationContext = useContext(NavigationContext)

  const [changedData, setChangedData] = useState(null)

  const updateData = (newData) => {
    setChangedData(newData)
  }

  const currentData = changedData ? changedData : data

  useEffect(() => {
    setChangedData(null)
    fetchContent()
  }, [contentKey])

  return (
    <div className={classes.root}>
      <div className={classes.toolBar}>
        <div className={classes.leftMenuBar}>
          <IconButton>
            <ArrowBackIcon onClick={onHide} />
          </IconButton>
        </div>
        <div className={classes.rightMenuBar}>
          { lastFetched && 
            <Box mr={1}>
              <Typography variant='body2' className={classes.infoText}>
                239 words
              </Typography>
            </Box>
          }
        </div>
      </div>
      <div className={classes.contentRoot}>
        { lastFetched && 
          <div className={classes.editorContainer}>
            <ContentEditor
              theme={theme}
              classes={classes}
              content={currentData}
              onContentChanged={(content) => updateData(content)}
              />
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...getContentRecord(state, ownProps.modelKey, ownProps.contentKey),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContent: () => {
      dispatch(fetchContentRecord(ownProps.modelKey, ownProps.contentKey))
    },
    Content: (data) => {
      dispatch(updateContentRecord(ownProps.modelKey, ownProps.contentKey, data))
    }
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContentView)
)