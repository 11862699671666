import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSetting, updateSetting, fetchSetting } from '../data/reducers/setting'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { SectionListView, MoreSectionItem, SectionItem, SectionCard } from './SettingView'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'


const styles = theme => ({
  root: {
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})

const ChannelSettingCard = ({ theme, classes, data, updateSetting }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)
  
  const channelTypeOptions = [
    { value: 'inapp', text: 'In App' },
  ]
  
  const fields = [
    { fieldName: 'name', type: 'text', label: 'Name', required: true },
    { fieldName: 'channel_type', type: 'option', label: 'Channel Type', options: channelTypeOptions }
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])
  

  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting(editData)
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }

  return (
    <SectionCard theme={theme} classes={classes}
      title={data.name}
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      {/*
      <MoreSectionItem hasMore={!showAllField}
        onClick={() => setShowAllField(!showAllField)}
      />
      */}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
    </SectionCard>
  )
}


const MessengerSettingCard = ({ theme, classes, updateSetting, ...props }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)
  
  const data = props.data.messenger_settings ? JSON.parse(props.data.messenger_settings) : {
    // Default setting
    launcher_visibility: 'floating',
    greeting_text: 'Hello',
    show_available_agents: true,
    hide_goapp_link: false
  }
  
  const launcherVisibilityOptions = [
    { value: 'floating', text: 'Floating Button' },
    { value: 'hidden', text: 'Hidden' },
  ]
  
  const fields = [
    { fieldName: 'launcher_visibility', type: 'option', label: 'Launcher Visibility', options: launcherVisibilityOptions },
    { fieldName: 'greeting_text', type: 'text', label: 'Greeting Text' },
    { fieldName: 'show_available_agents', type: 'switch', label: 'Show Available Agents' },
    { fieldName: 'hide_goapp_link', type: 'switch', label: 'Hide GOAPP Link' }
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])
  
  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting({
      messenger_settings: JSON.stringify({...data, ...editData})
    })
    
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }

  return (
    <SectionCard theme={theme} classes={classes}
      title="In App Messenger"
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      {/*
      <MoreSectionItem hasMore={!showAllField}
        onClick={() => setShowAllField(!showAllField)}
      />
      */}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
    </SectionCard>
  )
}

const ChannelsSettingView = ({ theme, classes, fetchChannelsSetting, channelsSetting, updateChannelsSetting }) => {

  useEffect(() => {
    fetchChannelsSetting()
  }, [])

  if (!channelsSetting.lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        { channelsSetting.isFetching &&
          <CircularProgress size='1.5rem' disableShrink={true} />
        }
      </div>
    )
  }

  const createChannelComponent = (channel) => {
    let component = ChannelSettingCard
    if (channel.channel_type == 'inapp')
      component = MessengerSettingCard

    return React.createElement(component, {
      theme: theme,
      classes: classes,
      data: channel,
      updateSetting: newData => {
        updateChannelsSetting({uid: channel.uid, ...newData})
      }
    })
  }
  
  return (
    <div className={classes.root}>
      <SectionListView title="Channels">
        { channelsSetting.data.map(channel => createChannelComponent(channel)) }
      </SectionListView>
    </div>    
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    channelsSetting: getSetting(state, 'channels'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchChannelsSetting: () => { dispatch(fetchSetting('channels', true)) },
    updateChannelsSetting: (data) => { dispatch(updateSetting('channels', data)) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChannelsSettingView)
)