import React, { Component, useState, useEffect, useContext } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ResizeDetector from 'react-resize-detector'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Link from '@material-ui/core/Link'

import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'


const useWindowSize = () => {
  const getWindowSize = () => ([ 
    window.innerWidth, 
    window.innerHeight 
    ])
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
      
      // This fixed issue on iPad where keyboard is showing and hiding could leave
      // our window size invalid.
      setTimeout(() => {
        setWindowSize(getWindowSize())
      }, 80) // Less than 50 doesn't work
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const styles = theme => ({
  root: {
//    width: '100%',
//    display: 'flex',
//    justifyContent: 'center'
//    backgroundColor: '#f5f5f5'
  },
  backgroundOverlay: {
    zIndex: -1,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },  
  topBar: {
    position: 'fixed',
    top: 0,
    zIndex: 1100, // App Bar
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  menuButton: {
//    marginLeft: 12,
//    marginRight: 12,
  },
  menuButtonHidden: {
    display: 'none',
  },
  linearProgress: {
    position: 'fixed',
    height: 2,
    overflow: 'hidden',
    width: '100%',
    zIndex: 1250 // Above Drawer (1200)
  },
  title: {
    flexGrow: 1,
    fontWeight: 800,
    paddingLeft: theme.spacing.unit * 2,
  },
  appBarSpacer: theme.mixins.toolbar,
  bottomBarSpacer: {
    height: theme.mixins.toolbar.height,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  bottomNavBarMenu: {
    width: '100%',
    position: 'fixed',
    bottom: 60 + 8,
    textAlign: 'center',
    'z-index': 1050 // Below appbar (1100)
  },
  bottomNavBar: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  sideContent: {
    width: 400,
//    paddingTop: theme.spacing(4)
  },
  sideContentCloseButton: {
    position: 'fixed',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    'z-index': 1350
  },  
  popupContentCloseButton: {
    position: 'fixed',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    'z-index': 1350
  },
  poweredBy: {
    color: '#aaa',
    fontSize: 12,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
})

export const NavigationContext = React.createContext({})

// Help scroll to top on page change
class ScrollToTopView extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.search) {
        let sp = new URLSearchParams(this.props.location.search)
        if (sp.get('popup') == 1)
          return
      }

      if (prevProps.location.search) {
        let sp = new URLSearchParams(prevProps.location.search)
        if (sp.get('popup') == 1)
          return
      }
  
      window.scrollTo(0, 0)
    }
  }
  
  render() {
    return this.props.children
  }
}

const ScrollToTop = withRouter(ScrollToTopView)

const NavigationController = ({ theme, classes, backgroundImageUrl, topBar, topBarOverlayColor, bottomBar, children, fitToWindow=true, openLink }) => {
  
  const [windowWidth, windowHeight] = useWindowSize()  
  
  const [contentInset, setContentInset] = useState({ top: 0, left: 0, right: 0, bottom: 0})
  const [topBarHidden, setTopBarHidden] = useState(false)
  const [bottomBarHidden, setBottomBarHidden] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [transparentBackground, setTransparentBackground] = useState(false)
  const [sideBarContent, setSideBarContent] = useState(null)
  const [popupContent, setPopupContent] = useState(null)
  const [activeDialog, setActiveDialog] = useState()
  
  const navigationContext = {
    contentInset,
    fitToWindow,
    openLink,
    hasTopBar: topBar ? true : false,
    hasBottomBar: bottomBar ? true : false,
    isTopBarHidden: topBarHidden,
    isBottomBarHidden: bottomBarHidden,
    setLoading: setLoading,
    setTransparentBackground: setTransparentBackground,
    showSideBar: (content, onHide=null) => {
      if (content)
        setSideBarContent({content, onHide})
      else
        setSideBarContent(null)
    },
    showPopup: (content, onHide=null) => {
      if (content)
        setPopupContent({content, onHide})
      else
        setPopupContent(null)
    },
    showDialog: (title, content, actions, onHide=null) => {
      setActiveDialog({title, content, actions, onHide})
    },
    hideDialog: () => {
      setActiveDialog(null)
    }
  }
  
  let rootStyle = {}
  
  if (fitToWindow) {
    rootStyle = {
      width: `${windowWidth}px`,
      height: `${windowHeight}px`,
      position: 'fixed',
      top: 0,
      left: 0,
    }
  }
  else {
    rootStyle = {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    }
  }

  let visibleContentInset = {
    ...contentInset,
    top: topBarHidden ? 0 : contentInset.top,
    bottom: bottomBarHidden ? 0: contentInset.bottom
  }

  let sideBarVisible = sideBarContent ? true : false
  let popupVisible = popupContent ? true : false
  
  const closePopup = () => {
    if (popupContent && popupContent.onHide)
      popupContent.onHide()
    else
      setPopupContent(null)
  }                                                                                                                                                                                                                                                                                                                     
  
  const closeSideBar = () => {
    if (sideBarContent && sideBarContent.onHide)
      sideBarContent.onHide()
    else
      setSideBarContent(null)
  }

  return (      
  <div className={classes.root} style={rootStyle}>
    <NavigationContext.Provider value={ navigationContext }>
    <ScrollToTop>
      <CssBaseline />

      { backgroundImageUrl &&
        <div className={classes.backgroundOverlay}
          style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
          >
        </div>
      }

      { topBar &&
        <>
          { backgroundImageUrl && topBarOverlayColor &&
            <>
              <div className={classes.topBar}
                style={{
                  height: contentInset.top,
                  backgroundColor: topBarOverlayColor,
                  overflow: "hidden",
                  // Without this, we can't tap on content area. Probably because of background div
                  pointerEvents: "none"
                }}
                >
                <div className={classes.topBar}
                  style={{
                    height: "100%",
                    height: contentInset.top,
                    // backgroundColor: topBarOverlayColor,
                    //filter: "blur(20px)",
                    opacity: ".3",
                    backgroundImage: `url("${backgroundImageUrl}")`,
                    backgroundSize: '100% auto',
                    backgroundRepeat: 'no-repeat',
                    filter: "blur(10px)",
                    transform: "scale(1.2)"
                  }}
                  />
              </div>
            </>
          }

          <div className={classes.topBar}>
            <ResizeDetector
              handleHeight
              render={({ height }) => {
                if (height != contentInset.top)
                  setContentInset({...contentInset, top:height})
                return topBar
              }}
            />
          </div>
        </>
      }

      {/* Warning: Do not use <main> tag. It's not supported in IE */}
      <div className={classes.content}
        style={{ 
          backgroundColor: transparentBackground ? 'inherit' : 'white',
          marginTop: visibleContentInset.top,
          //minHeight: `calc(100vh - ${contentInset.top}px)`,
          height: `calc(100% - ${contentInset.top}px)`,
          position: 'relative',
//          display: 'flex',
//          flexDirection: 'column'
        }}
        >        
        
        { children }        

        <div style={{ height: visibleContentInset.bottom }} />
        
        { activeDialog }
      </div>
            
      <div className={classes.linearProgress} 
        style={ isLoading ? {} : { display: 'none' } } >
        <LinearProgress />
      </div>
    </ScrollToTop>
    </NavigationContext.Provider>
  </div>
  )
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    NavigationController
  )
)