import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActions'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },  
})

const CardWidget = ({ classes, theme, content, openLink, sendMessage }) => {

  const onClick = (button) => {
    if (button.button_type && button.button_type == 'callback') {
      let data = {
        ...button
        }
      // alert(JSON.stringify(data, null, 2))
      sendMessage(JSON.stringify(data))
    }
    else if (button.link_to)  
      openLink(button.link_to)
  }

  return (
    <Card className={classes.root}>
      { content.images && content.images.length > 0 &&
        <CardMedia
          className={classes.media}
          image={ content.images[0].image_url }
          title={ content.title }
        />
      }
      <CardContent>
        <Typography variant="body1" color="textSecondary" component="p">
          { content.title }
        </Typography>
        { content.subtitle &&
	        <Typography variant="body2" color="textSecondary" component="p">
    	      { content.subtitle }
	        </Typography>
        }
	    }
      </CardContent>
      
      { content.buttons &&
      <>
      <Divider />
      <CardActionArea>
        <Box flex={1} flexDirection='column'>
        { content.buttons.map(btn =>
          <Button variant='text' color='primary' fullWidth onClick={()=>onClick(btn)}>
            { btn.title }
          </Button>
        )}
        </Box>
      </CardActionArea>
      </>
      }
    </Card>
  );
};

export default withStyles(styles, { withTheme: true })(
  CardWidget
  )
