import { receiveNewMessage, receiveConversation, receiveConversationMessagesUpdate, fetchConversationCounts, wsStateChanged, WSSTATE } from '../data/reducers/conversation'
import { receiveAvailability } from '../data/reducers/queue'

// Actions

export const wsConnect = host => ({ type: 'WS_CONNECT', host });
export const wsConnecting = host => ({ type: 'WS_CONNECTING', host });
export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = host => ({ type: 'WS_DISCONNECTED', host });
export const wsSend = (command, data) => ({ type: 'WS_SEND', command, data });

export const conversationMiddleware = () => {
  let socket = null;

  const onOpen = store => (event) => {
    console.log('websocket open', event.target.url);
    store.dispatch(wsConnected(event.target.url));
  };

  const onClose = store => () => {
    store.dispatch(wsDisconnected());
  };

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    console.log('receiving server message');

    //alert("onMessage: " + payload.type)
    //alert("data: " + JSON.stringify(payload.data, null, 2))

    switch (payload.type) {
      case 'new_message':
        store.dispatch(receiveNewMessage(payload.data.conversation.uid, payload.data));        
        break;
      case 'messages_updated':
        //alert("onMessage: " + payload.type)
        store.dispatch(receiveConversationMessagesUpdate(payload.data.conversation.uid, payload.data.conversation, payload.data.messages));        
        break;        
      case 'update_conversation':
        store.dispatch(receiveConversation(payload.data.uid, payload.data));
        // Refresh conversation counts
        // NOTE: This might be heavy for server, but it ensure that conversation
        // counts are realtime
        store.dispatch(fetchConversationCounts(true))
        break;
      case 'agent_status_changed':
        // We're still using single queue mode
        store.dispatch(receiveAvailability("0", payload.data));
        //store.dispatch(receiveAvailability(payload.data.queue.uid, payload.data));
        break;
      default:
        break;
    }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
//          socket.close();
          break
        }        

//        alert(action.host)
        // connect to the remote host
        socket = new WebSocket(action.host);
        
//        store.dispatch(wsConnecting);
        store.dispatch(wsStateChanged(WSSTATE.Connecting))

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log('websocket closed');
        break;
        
      case 'WS_START':
        // Send start command

      case 'WS_CONNECTED':
        store.dispatch(wsStateChanged(WSSTATE.Connected))
        break;
        
      case 'WS_CONNECTING':
        store.dispatch(wsStateChanged(WSSTATE.Connecting))
        break;
        
      case 'WS_DISCONNECTED':
        store.dispatch(wsStateChanged(WSSTATE.Disconnected))
        socket = null          
        break;
        
      case 'WS_SEND':
        if (socket == null)
          break;
        console.log('sending a message: ', action.command);
        socket.send(JSON.stringify({ command: action.command, data: action.data }));
        break;
        
      default:
        console.log('the next action:', action);
        return next(action);
    }
  };
};

export default conversationMiddleware();