import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getHistoricalData, fetchHistoricalData } from '../data/reducers/dashboard'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  root: {
    margin: theme.spacing(2)
  },
  container: {
    maxHeight: 440,
  },
})

const RowData = ({ theme, classes, column, header, row, style }) => {
  let align = 'left'
  if (column.type === 'number')
    align = 'right'
  let customStyle = style || {}

  return (
    <TableCell key={column.key} align={align} style={customStyle}>
      { header ?
        column.label
      :
        row[column.key]
      }
    </TableCell>
  )
}

const RowDataHeader = (props) => {
  let style = {}
  if (props.column.type === "number")
    style = {
      color: "#aaa",
      fontSize: ".8rem"
    }

  return (
    <RowData header={true} style={style} {...props} />
  )
}

const HistoricalDataView = ({ theme, classes, dataType, table, columns, startDate, endDate, fetchHistoricalData, data, ...props }) => {

  useEffect(() => {
    fetchHistoricalData()

    const refreshTimer = setInterval(fetchHistoricalData, 10000)
    return () => {
        clearInterval(refreshTimer)
    }

  }, [dataType, startDate, endDate])

  const rows = data.lastFetched && data.data[table]  
  const defaultColumns = [
    { label: 'Name', key: 'name' },
    { label: 'Count', key: 'count', type: 'number' },
  ]

  return (
    <Paper className={classes.root}>
      { !data.lastFetched && data.isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
            <CircularProgress size='1.5rem' disableShrink={true} />        
        </Box>
      }
      { data.lastFetched &&    
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {(columns || defaultColumns).map((column) => (
                  <RowDataHeader theme={theme} classes={classes} column={column} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>              
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {(columns || defaultColumns).map((column) => (
                      <RowData theme={theme} classes={classes} column={column} row={row} />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
      }
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getHistoricalData(state, ownProps.dataType, ownProps.startDate, ownProps.endDate)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchHistoricalData: () => {
      dispatch(fetchHistoricalData(ownProps.dataType, ownProps.startDate, ownProps.endDate, true))
    }
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(HistoricalDataView)
  )
)
