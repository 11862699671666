import React, { Component, useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSetting, updateSetting, fetchSetting } from '../data/reducers/setting'

import { NavigationContext } from '../controllers/NavigationController'

import moment from 'moment'

import { SectionListView, MoreSectionItem, SectionItem, SectionCard } from './SettingView'
import InputField from './InputField'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'


const styles = theme => ({
  root: {
  },
  headerRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),    
  },
  sectionCardRoot: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),    
  },
  sectionCardHeading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#888'
  }
})


const APIKeyForm = ({ theme, classes, app, open, onClose }) => {
  
  return (
    <Dialog open={open} onClose={onClose}>    
      <DialogTitle>API Key</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          <InputField name='api_key'
            theme={theme} classes={classes}
            inputType='text'
            label='API Key'
            value={ app.uid }
            readOnly
            />              
        </Box>
      
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>        
      </DialogActions>
    </Dialog>        
  )
}


const AppSettingCard = ({ theme, classes, data, updateSetting }) => {

  const [editData, setEditData] = useState({})
  const [isValids, setIsValids] = useState({})
  const [showAllField, setShowAllField] = useState(true)
  const [editingField, setEditingField] = useState(null)
  
  const [openAPIKeyForm, setOpenAPIKeyForm] = useState(false)

  const appTypeOptions = [
    { value: 'web', text: 'Web App' },
    { value: 'mobile', text: 'Mobile App' },
  ]
  
  const apiKeyFieldValue = (item, value) => {
    return (
      <Button variant='outlined' onClick={() => setOpenAPIKeyForm(true)}>
        Show
      </Button>
    )
  }
  
  const fields = [
    { fieldName: 'name', type: 'text', label: 'Name', required: true },
    { fieldName: 'app_type', type: 'option', label: 'App Type', options: appTypeOptions },
    { fieldName: 'api_key', type: 'text', label: 'API Key', renderValue: apiKeyFieldValue }
  ]
  
  const filteredFields = fields.reduce((p, f) => {
    if (!showAllField && !data[f.fieldName])
      return p

    return [...p, f]
  }, [])
  

  const onSave = () => {
    if (Object.values(isValids).includes(false)) {
      alert("Please fix all fields marked red")
      return
    }

    updateSetting(editData)
    setEditData({})
    setIsValids({})
  }

  const onCancel = () => {
    setEditData({})
    setIsValids({})
  }
  
  return (
    <SectionCard theme={theme} classes={classes}
      title={data.name}
      defaultExpanded={true}
      >
      { filteredFields.map(item => (
        <SectionItem
          theme={theme}
          classes={classes}
          item={item}
          isEditing={editingField == item.fieldName ? true : false}
          value={item.fieldName in editData ? editData[item.fieldName] : data[item.fieldName]}
          onChange={(value) => {
            setEditData({...editData, [item.fieldName]: value})
            //setDataChanged(true)
          }}
          setEditing={(edit) => setEditingField(edit ? item.fieldName : null)}
          isValid={item.fieldName in isValids ? isValids[item.fieldName] : true}
          setIsValid={(valid) => setIsValids({...isValids, [item.fieldName]: valid})}
        />
      ))}
      {/*
      <MoreSectionItem hasMore={!showAllField}
        onClick={() => setShowAllField(!showAllField)}
      />
      */}
      { editData && Object.keys(editData).length > 0 &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(1) }}>
          <Button color='primary' onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      }
      
      { openAPIKeyForm &&
        <APIKeyForm theme={theme} classes={classes} 
          app={data} 
          open={true} 
          onClose={() => setOpenAPIKeyForm(false)} 
          />
      }
    </SectionCard>
  )
}

const AppsSettingView = ({ theme, classes, fetchAppsSetting, appsSetting, updateAppsSetting }) => {

  useEffect(() => {
    fetchAppsSetting()
  }, [])
    
  if (!appsSetting.lastFetched) {
    return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2)
        }}>
        { appsSetting.isFetching &&
          <CircularProgress size='1.5rem' disableShrink={true} />
        }
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <SectionListView title="Applications">        
        { appsSetting.data.map(app =>
          <AppSettingCard theme={theme} classes={classes} data={app}
            updateSetting={data => updateAppsSetting({uid: app.uid, ...data})}
          />
        )}
      </SectionListView>
    </div>    
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    appsSetting: getSetting(state, 'applications'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAppsSetting: () => { dispatch(fetchSetting('applications', true)) },
    updateAppsSetting: (data) => { dispatch(updateSetting('applications', data)) },
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppsSettingView)
)