import React, { useState, useRef, useEffect, useContext } from 'react'

import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  textField: {
  },
  formControl: {
    // Text field has top spacing. Let's add it
    // to other control
    marginTop: theme.spacing(2)
  }
})

const FormInput = ({ classes, theme, content, sendMessage, openLink, animated }) => {

  const formFields = content.form_fields

  const [formValues, setFormValues] = useState({})
  const [errorTexts, setErrorTexts] = useState({})
  
  const validateEmail = (email) => {
  }
  
  const isValid = (val, required=true, type=null) => {
    if (!val || val == undefined)
      val = ""
    else
      val = val.trim()
    if (required && val.length == 0)
      return false
    if (val.length > 0)
      if (type == 'email') {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(val).toLowerCase()))
          return false
      }
    
    return true
  }
    
  const validateAndSave = () => {
    let errors = {}
    
    formFields.forEach((ff) => {
      if (!isValid(formValues[ff.field_name], true, ff.validation))
        errors[ff.field_name] = "Invalid"
    })
    
    if (Object.keys(errors).length > 0) {
      setErrorTexts(errors)
      alert("Please check some invalid input.")
      return
    }

    let text = formFields.map((ff) => {
        let val = formValues[ff.field_name]
        if (!val)
          val = ""
        else if (ff.input_type == 'option' || ff.input_type == 'radio')
          val = ff.options.find(o => o.value == val).text
        return `${ff.label}: ${val}`
      }).reduce((p, n) => ( p + ", " + n ))
    
    sendMessage(JSON.stringify({ 
      form_values: formValues, 
      text: text 
    }))  
  }
  
  const onValueChanged = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.value
      })
  }
  
  let controls = formFields.map((ff) => {
    if (ff.input_type == 'text') {
      return (
        <TextField
          label={ff.label}
          value={formValues[ff.field_name]}
          error={ff.field_name in errorTexts ? true : false}
          onChange={onValueChanged(ff.field_name)}
          className={classes.textField}
          margin="normal"
          fullWidth
        />      
      )
    }
    else if (ff.input_type == 'option') {
      return (
        <FormControl fullWidth className={classes.formControl}>
         <InputLabel id="demo-simple-select-label">{ff.label}</InputLabel>
          <Select
            value={formValues[ff.field_name]}
            onChange={onValueChanged(ff.field_name)}
          >
            { ff.options.map((o) => (
              <MenuItem value={o.value}>{o.text}</MenuItem>
            ))}            
          </Select>
        </FormControl>
      )
    }    
    else if (ff.input_type == 'radio') {
      return (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{ff.label}</FormLabel>
          <RadioGroup name={ff.field_name} value={formValues[ff.field_name]} onChange={onValueChanged(ff.field_name)} row>
          
            { ff.options.map((o) => (
              <FormControlLabel
                control={<Radio color="primary" />}
                value={o.value}
                label={o.text}
              />
            ))}
            
          </RadioGroup>
        </FormControl>
      )
    }    
  })

  return (
    <Box mt={1} ml={3}>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' width='100%' flexWrap='wrap' ml={1} mr={1}>
        <Paper className={classes.root}>
          { controls }
          
          <Box mt={2}/>
          
          <Button variant='outlined' color='primary' onClick={validateAndSave}>
            Submit
          </Button>
        </Paper>
      </Box>
    </Box>  
  )
}

export default withStyles(styles, { withTheme: true })(
  FormInput
  )
