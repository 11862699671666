import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getContentIndex, fetchContentIndex } from '../data/reducers/content'

import { NavigationContext } from './NavigationController'

import PageEditor from '../views/PageEditor'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    // Larger bottom to accomodate shadow
    paddingBottom: theme.spacing(8)    
  },
  sectionTitle: {
    fontWeight: 700
  },
  card: {
  },
  cardTitle: {
    fontWeight: 500,    
  }
})

const PageCard = ({ theme, classes, business, item, onEdit }) => {

  return (
    <Card className={classes.root} elevation={18} square={true}>
      <CardContent>
        <Typography variant="body1" component="h2" className={classes.cardTitle}>
          { item.title }
        </Typography>
        <Typography color="textSecondary" variant="body2">
          { item.total_view } views
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" onClick={onEdit}>Edit</Button>
      </CardActions>
    </Card>
  )  
}

const PagesController = ({ history, theme, match, classes, user, business, ...props }) => {

  const { lastFetched, fetchPageList, isFetching, data } = props
  
  const navigationContext = useContext(NavigationContext)

  let pageKey = null
  if (match && match.params.pageKey)
    pageKey = match.params.pageKey

  useEffect(() => {
    fetchPageList()

    return () => {
    }
  }, [])

  const showPageEditor = (key) => {
    history.push("/pages/edit/" + key)
  }
  
  const hidePageEditor = () => {
    history.push("/pages")
  }

  if (pageKey) {
    return (
      <PageEditor
        pageKey={pageKey}
        onHide={hidePageEditor}
        />
    )
  }

  return (
    <Container className={classes.root} maxWidth="xl">
      <Box mt={4} mb={2}>
        <Typography component="h1" variant="h6" className={classes.sectionTitle}>
          Draft
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Grid container className={classes.root} spacing={4}>
          { lastFetched && data.records.filter(p => (
              p.component == 'Page' && p.posts.length <= 0
            )).map(p =>
            <Grid item xs={12} sm={6} lg={4}>
              <PageCard theme={theme} classes={classes}
                item={p}
                onEdit={() => showPageEditor(p.key)}
                />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={4} mb={2}>
        <Typography component="h1" variant="h6" className={classes.sectionTitle}>
          Published
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Grid container className={classes.root} spacing={4}>
          { lastFetched && data.records.filter(p => (
              p.component == 'Page' && p.posts.length > 0
            )).map(p =>
            <Grid item xs={12} sm={6} lg={4}>
              <PageCard theme={theme} classes={classes}
                item={p}
                onEdit={() => showPageEditor(p.key)}                
                />
            </Grid>
          )}
        </Grid>
      </Box>

    </Container>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...getContentIndex(state, 'page', 'default'),
    user: state.user,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    fetchPageList: (refresh=false) => {
      dispatch(fetchContentIndex('page', 'default', null, refresh))
    },
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PagesController)
  )
)
