import React, { Component, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getConversationGroups, getConversationGroup, fetchConversationCounts, fetchConversationList, getConversation, closeConversation, reopenConversation, WSSTATE } from '../data/reducers/conversation'
import { getQueue } from '../data/reducers/queue'

import SplitListController from './SplitListController'
import { NavigationContext } from './NavigationController'

import Chat from '../views/Chat'
import ChatTitleBar from '../views/ChatTitleBar'
import ContactSideView from '../views/ContactSideView'
import ToolBar from '../views/ToolBar'
import FilterBar from '../views/FilterBar'

import QueueView from '../views/QueueView'

import moment from 'moment'
import ReactLoading from 'react-loading'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import InboxIcon from '@material-ui/icons/Inbox'

import Dialog from '@material-ui/core/Dialog'

const styles = theme => ({
  root: {
  },
  filterTitleButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  filterTitleButtonBold: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },    
})

const ChatItem = ({ theme, classes, business, user, item, selected, onClick }) => {
  let title = business.name
  let sender = ""
  let messageStatus = ""
  let preview = "..."
  let date = ""
  let stat = ""
  
//  alert(JSON.stringify(item, null, 2))
  

  title = item.parties.map(p => {
    if (p.contact) {
      let name = p.contact.first_name
      if (p.contact.last_name)
        name += " " + p.contact.last_name
      if (!name || name.length <= 0)
        name = "New Contact"
      return name
    }    
    else
      return null
  }).reduce((p, n) => {
    if (p && n)
      return p + ", " + n
    else if (n)
      return n
    else
      return p
  })
  
  if (!title && item.queue)
    title = item.queue.name
  
//    alert(JSON.stringify(item, null, 2))
  let msg = item.last_message
  if (item.messages && item.messages.length > 0)
    msg = item.messages[item.messages.length-1]
  if (msg) {
    preview = msg.body
    if (msg.attachments && msg.attachments !== undefined && msg.attachments.length > 0) {
        preview = "Photo"
    }
    
    if (preview.startsWith("{") && preview.endsWith("}"))
      preview = JSON.parse(preview)['text']
      
    if (preview)
      preview = preview.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, '$1')
    
    if (msg.isSending)
      date = "Sending..."
    else {
      if (msg.sent_at)
        date = moment(msg.sent_at).fromNow(true)
      
      if (msg.sender.agent) {
        if (msg.sender.agent.uid == user.userInfo.uid)
          sender = "You"
        else {
          sender = msg.sender.agent.first_name
          if (msg.sender.last_name)
            sender += " " + msg.sender.last_name
        }
        
        if (msg.read_at)
          messageStatus = "Read"
        else
          messageStatus = "Not read"        
      }
    }
  }

//    if (msg.agent)
//      sender = msg.agent.first_name
//    else if (msg.service)
//      sender = msg.service.first_name
  
  date = moment(item.last_active).fromNow(true)
  
  let newCount = item.new_count
  
  if (item.queue && !item.answered_at && newCount <= 0)
    newCount = 1
  
  if (item.new_count)
    stat = " (New)"
  else if (item.unread_count)
    stat = " (Not Read)"
    
  let typing_at = 0
  item.parties.forEach(p => {
    if (p.contact && p.typing_at && moment(p.typing_at).toDate() > typing_at)
      typing_at = moment(p.typing_at).toDate()
  })

  const [typingTimeout, setTypingTimeout] = useState()

  useEffect(() => {
    if (typing_at && !typingTimeout) {
      const interval = new Date() - typing_at
      if (interval < 10000) {
        if (typingTimeout)
          clearTimeout(typingTimeout)
        setTypingTimeout(setTimeout(() => {
          //alert('timeout')
          setTypingTimeout(null)
        }, 10000 - interval))
      }
    }
  }, [typing_at])
  
  let borderDiv = null
//  let borderStyle = {}
  if (selected) {
    // Note: left border will push list item content to the right,
    // so I'll use div border instead
//    borderStyle['borderLeft'] = `3px solid ${theme.palette.primary.main}`
    borderDiv = (
      <div style={{
        width: '4px',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main
        }}/>
      )
  }

  return (
    <ListItem alignItems="flex-start" 
      style={{ cursor: 'default' }}
      onClick={() => onClick(item)}
      >
      { borderDiv }
      {/*
      <ListItemAvatar>
        <Avatar alt="You" src="" />
      </ListItemAvatar>
      */}
      <ListItemText
        primary={ 
          <>
            <Box display='flex' alignItems='baseline'>          
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }} noWrap={true}>
                { title }
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  style={{ color: 'grey' }}
                  noWrap={true}
                >
                  { date }
                </Typography>          
              </Box>
            </Box>
          </>
        }
        secondary={
          <>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                { typing_at && (new Date() - typing_at) < 10000 ?
                  <Box style={{ height: 20, position: 'relative', top: -6 }}>
                    <ReactLoading type='bubbles' color='#555' width={32} height={32} />
                  </Box>

                :
                <Typography
                  variant="body2"
                  color="textPrimary"
                  noWrap={false}
                >
                  { sender && ( 
                    <> { sender }: </>
                  )}
                  { preview }
                  { messageStatus &&
                    <>
                      &nbsp;({ messageStatus })
                    </>
                  }
                </Typography>
                }
              </Box>
              { newCount > 0 &&
                <Box style={{
                    backgroundColor: "red",
                    height: "20px",
                    minWidth: "20px",
                    padding: "0px 6px",
                    borderRadius: "10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  { newCount }
                </Box>
              }
            </Box>
            { item.queue &&
            <Box style={{
                fontSize: ".8rem",
                color: "#999"
              }}>
              { item.queue.name }
            </Box>
            }
          </>
        }
      />
    </ListItem>
  )  
}

const ChatController = ({ history, theme, match, classes, user, business, queue, ...props }) => {

  const { groups, fetchConversationGroups, fetchConversationList, closeConversation, reopenConversation } = props
  
  const navigationContext = useContext(NavigationContext)

  let conversationKey = -1
  if (match && match.params.conversationKey)
    conversationKey = match.params.conversationKey
    
  const [internalListKey, setInternalListKey] = useState(null)
  const [internalConversationKey, setInternalConversationKey] = useState(null)
    
  let listKey = internalListKey || props.listKey
  conversationKey = internalConversationKey || conversationKey  
  
  const { isFetching, lastFetched, data} = {
    ...props.getConversationGroup(listKey)
  }    
  
  const [showSidePane, setShowSidePane] = useState(true)
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1)
  const [showQueueDetail, setShowQueueDetail] = useState(false)

  const listTypes = [
    { key: 'not_assigned', title: 'Unassigned', icon: PeopleIcon },
    { key: 'assigned_to_me', title: 'Me', icon: PersonIcon },
    { key: 'open', title: 'Open', icon: InboxIcon },
    { key: 'closed', title: 'Closed', icon: InboxIcon }
  ]

  const filterConversations = (conversations, filterKey) => {
    if (!conversations)
      return []
    return conversations

    return conversations.filter(c => {
      if (filterKey == 'not_assigned')
        return !c.data.closed_at && c.data.queue && !c.data.assigned_to
      else if (filterKey == 'open')
        return !c.data.closed_at
      else if (filterKey == 'closed')
        return c.data.closed_at
      else if (filterKey == 'assigned_to_me')
        return !c.data.closed_at && c.data.assigned_to && c.data.assigned_to.uid == user.userInfo.uid                    
      else
        return true
    })
  }

  const visibleListTypes = listTypes.map(l => {
    let count = 0
    if (l.key in groups)
      count = groups[l.key].counts.count || 0
    return {
      ...l,
      count
    }
  })

  const conversations = lastFetched && filterConversations(data.records, listKey)
    .sort((a, b) =>
      moment(b.last_active).toDate().getTime() - moment(a.last_active).toDate().getTime()
    ) || []

  const showContactDetail = (contact) => {
    if (!showSidePane)
      setShowSidePane(true)
    if (selectedContact != contact)
      setSelectedContact(contact)
  }
  
  const hideContactDetail = () => {
    setShowSidePane(false)
    setSelectedContact(null)
  }

  // This will be called on component init  
  useEffect(() => {
    fetchConversationList(listKey)

    return () => {
    }        
  }, [listKey])

  useEffect(() => {
    setSelectedContact(null)
  }, [conversationKey])

  const showConversation = (key) => {
    if (navigationContext.openLink) {
      if (listKey)
        navigationContext.openLink(`/chat/${listKey}/view/${key}`)
      else
        navigationContext.openLink("/chat/view/" + key)
    }
    else
      setInternalConversationKey(key)
  }
  
  const showList = (key) => {
    if (navigationContext.openLink)
      navigationContext.openLink("/conversation/" + key)
    else
      setInternalListKey(key)
  }
  
  const hideConversation = () => {
    if (navigationContext.openLink)    
      history.push("/chat")
    else
      setInternalConversationKey(null)
  }
  
  const conversation = conversationKey != -1 && lastFetched && data.records.find(c => c.uid == conversationKey)

  if (false && conversationKey != -1 && lastFetched) {
    const rowIndex = conversations.findIndex(c => c.key == conversationKey)
    if (rowIndex == -1) {
      // Select row at current key index
      if (selectedRowIndex >= 0 && selectedRowIndex < conversations.length)
        showConversation(conversations[selectedRowIndex].key)
      else if (conversations.length > 0)
        showConversation(conversations[0].key)
      else
        hideConversation()
    }
    else if (rowIndex != selectedRowIndex) {
      setSelectedRowIndex(rowIndex)
    }
  }  
  
  const renderListItem = (itemPosition) => {
    if (!lastFetched)
        return null

    let item = conversations[itemPosition]
    
    return (
      <ChatItem theme={theme} classes={classes} business={business} user={user}
        item={item}
        selected={conversationKey == item.uid}
        onClick={()=>showConversation(item.uid)}
      />
    )

    // Testing
    return {
      avatar: <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/3.jpg" />,
      primaryText: <>Oui Oui</>,
      secondaryText: 
        <>
          <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary"
          >
            Sandra Adams
          </Typography>
          {' — Do you have Paris recommendations? Have you ever…'}
        </>
    }
  }
  
  const getActionButton = (conversation) => {
    if (conversation.data.closed_at)
      return (
        <Button variant='contained' size='small' color='default'
          onClick={() => reopenConversation(conversationKey)}
          >
          Reopen
        </Button>
      )
    else
      return (
        <Button variant='contained' size='small' color='default'
          disableElevation={true}
          onClick={() => closeConversation(conversationKey)}
          >
          End Chat
        </Button>
      )  
  }

  if (showSidePane && !selectedContact) {
    if (conversationKey != -1 && conversation) {
      let party = conversation.parties.find(p => p.contact ? true : false)
      if (party)
        showContactDetail(party.contact)
    }
  }            


  let rightPaneContent = null
  if (conversationKey != -1 && showSidePane) {
    rightPaneContent = (
      <div>
        <ToolBar variant='transparent' style={{ position: 'sticky' }}
          menuItems={
            <>
              <IconButton onClick={hideContactDetail}>
                <CloseIcon />
              </IconButton>
            </>
          }
          />
        { selectedContact &&
          <ContactSideView contactKey={selectedContact.uid} initialContact={selectedContact} />
        }
      </div>
    )
  }

  return (
    <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <FilterBar
        leftItems={(
          <>
            { visibleListTypes.map((item, index) =>
              <Box 
                className={item.key == listKey ? classes.menuItemSelected : classes.menuItemDefault}>
                <Button style={{ color: item.key == listKey ? 'black' : 'gray'}}
                  className={item.key == listKey ? classes.filterTitleButtonBold : classes.filterTitleButton}
                  component={Link}
                  to={`/chat/${item.key}`}
                  onClick={()=>showList(item.key)}
                  >
                  { item.icon && React.createElement(item.icon, { color: 'inherit' }) }
                  { item.title }
                  
                  { item.count > 0 && `(${item.count})`}
                </Button>
              </Box>
              )}
          </>
        )}
        rightItems={(
          <>
            { queue.lastFetched && queue.data.agents &&
              <Button style={{ color: 'black', textTransform: 'none' }}
                onClick={() => setShowQueueDetail(true)}
                >
                { queue.data.agents.filter(a => a.is_online && a.availability == 'available').length } Available Agents
              </Button>
            }
          </>
        )}
      />
      <div style={{ 
          flexGrow: 1, 
          height: '50%' // Without this, our split list won't stretch 100% height. It's weird
          }}>
        <SplitListController
          topInset={navigationContext.contentInset.top + 40}
          itemCount={lastFetched ? conversations.length : 0}
          renderListItem={renderListItem}
          fixedContentPane={true}
          rightPaneContent={rightPaneContent}
          >
          { conversationKey > 0 &&
            <Chat 
              conversationKey={conversationKey} 
              openChat={showConversation} 
              closeChat={hideConversation}
              renderHeader={ (conversation) =>
                <ChatTitleBar
                  business={business}
                  conversation={conversation}
                  onViewContact={(contact) => showContactDetail(contact)}
                  actionButton={getActionButton(conversation)}
                 />
              }
            />
          }
        </SplitListController>
      </div>

      <Dialog
        onClose={() => setShowQueueDetail(false)} 
        maxWidth='xs'
        fullWidth={true}
        open={showQueueDetail}>
        <QueueView />
      </Dialog>

    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let listKey = 'assigned_to_me'
  if (ownProps.match && ownProps.match.params.listKey)
    listKey = ownProps.match.params.listKey
    
  return {
    getConversationGroup: (listKey) => getConversationGroup(state, listKey),
    //...getConversationGroup(state, listKey),
    listKey,
    user: state.service.user,
    business: getBusinessInfo(state, '0'),
    queue: getQueue(state, "0"),
    groups: getConversationGroups(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    fetchConversationCounts: () => {
      dispatch(fetchConversationCounts(true))
    },
    fetchConversationList: (groupKey) => {
      dispatch(fetchConversationList(groupKey, true))
    },
    closeConversation: (key) => {
      dispatch(closeConversation(key))
    },
    reopenConversation: (key) => {
      dispatch(reopenConversation(key))
    }
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ChatController)
  )
)
