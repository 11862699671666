import React, { useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'

import { Page as AlphaPage } from 'alpha-page'
import SplitView from './SplitView'

import { NavigationContext } from '../controllers/NavigationController'
import { getContentRecord, fetchContentRecord, updateContentRecord } from '../data/reducers/content'

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const styles = theme => ({
  root: {
  },
  contentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  pageTitle: {
    fontWeight: 700
  },
  sectionTitle: {
    fontWeight: 700
  }
})

const NavigationPane = ({ theme, classes, page, isFetching, lastFetched, data, isChanged, onSave, onClose }) => {
  if (!lastFetched || !data.sections)
    return null

  const SectionItem = ({title, textColor='inherit', actionIcon, onClick}) => (
    <ListItem alignItems="flex-start">
      <ListItemText
        primary={ 
          <>
            <Typography variant='body2' style={{ color: textColor }}>
            { title }
            </Typography>                
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={onClick}>
          { actionIcon }
        </IconButton>
      </ListItemSecondaryAction>              
    </ListItem>    
  )

  return (
    <div>
      <Box className={classes.contentPadding} mt={2} mb={2}>
        <Typography component='h1' variant='body1' className={classes.pageTitle}>{data.title}</Typography>
      </Box>
      <Box className={classes.contentPadding} mt={1}>
        <Typography component='h1' variant='body2' className={classes.sectionTitle}>Sections</Typography>
      </Box>
      <Box
        mt={1}
        style={{ borderTop: 'solid 1px #ccc', borderBottom: 'solid 1px #ccc'}}
        >
        <List
          style={{ paddingTop: 0, paddingBottom: 0 }}
          >
          { data.sections.map(s => (
            <SectionItem 
              title={s.name}
              actionIcon={<MoreHorizIcon />}
              onClick={null}
              />
            )).reduce((prev, next) => [prev, <Divider />, next])
          }

          <Divider/>
          <SectionItem
            title='New Section'
            textColor={theme.palette.primary.main}
            actionIcon={<AddIcon />}
            onClick={null}            
            />

        </List>
      </Box>
      <Box mt={1} display='flex' flexDirection='column' style={{ padding: theme.spacing(1) }}>
        <Button variant='contained' color='primary' disabled={!isChanged} onClick={onSave} fullWidth>Save</Button>
        <Box mt={1} />
        <Button variant='contained' fullWidth onClick={onClose}>Close</Button>
      </Box>

    </div>
  )
}

const DummySection = (props) => {
  return (
    <div>
    </div>
  )
}

const PageEditor = ({ theme, classes, ...props }) => {

  const { pageKey, fetchPage, savePage, isFetching, lastFetched, data } = props
  const { onHide } = props

  const navigationContext = useContext(NavigationContext)

  const [changedData, setChangedData] = useState(null)

  const updateData = (newData) => {
    setChangedData(newData)
  }

  useEffect(() => {
    setChangedData(null)
    fetchPage()
  }, [pageKey])

  return (
    <div className={classes.root}>
      <SplitView
        topInset={navigationContext.contentInset.top}
        leftPaneStyle={{width: '200px', minWidth: '200px'}}
        leftPaneContent={
          <NavigationPane
            theme={theme}
            classes={classes}
            isFetching={isFetching}
            lastFetched={lastFetched}
            data={changedData ? changedData : data}
            isChanged={changedData ? true : false}
            onSave={() => savePage(changedData)}
            onClose={onHide}
            />
        }
      	>

        { lastFetched && 
    	    <AlphaPage
    	      editMode={true}
            isFetching={isFetching}
            lastFetched={lastFetched}
            data={changedData ? changedData : data}
            onDataChanged={updateData}
            newSectionMaps={{
              BlogFeed: DummySection
            }}
            />
        }
	    </SplitView>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...getContentRecord(state, "page", ownProps.pageKey),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPage: () => {
      dispatch(fetchContentRecord('page', ownProps.pageKey))
    },
    savePage: (data) => {
      dispatch(updateContentRecord('page', ownProps.pageKey, data))
    }
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageEditor)
)