import React, { Component, useState, useEffect, useContext } from 'react'

import ResizeDetector from 'react-resize-detector'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    direction: 'row'
  },
  leftPane: {
    minWidth: 300,
    width: '25%',
  },
  leftPaneContainer: {
    position: 'fixed',
    overflow: 'scroll',
    borderRight: 'solid 1px #eee'
//    backgroundColor: '#eaeaea'
  },
  contentPane: {
    flexGrow: 1
  },
  contentPaneContainer: {
  },
  rightPane: {
    minWidth: 300,
    width: '25%',
  },
  rightPaneContainer: {
    position: 'fixed',
    overflow: 'scroll',
    borderLeft: 'solid 1px #eee'
//    backgroundColor: '#eaeaea'
  },
})

const useWindowSize = () => {
  const getWindowSize = () => ({ 
    width: window.innerWidth, 
    height: window.innerHeight 
    })
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const SplitView = ({ theme, classes, children, ...props }) => {

  const { leftPaneStyle, leftPaneContent } = props
  const { rightPaneStyle, rightPaneContent } = props

  const { topInset=0, bottomInset=0 } = props
  const { fixedContentPane=false } = props

/*
  // This doesn't work on mobile where we could have different window size
  // on scrolling
  let listPaneContainerHeight = '100vh'
  if (topInset > 0 || bottomInset > 0)
    listPaneContainerHeight = `calc(100vh - ${topInset-bottomInset}px)`
*/

  const { width, height } = useWindowSize()
  let containerHeight = (height - (topInset-bottomInset)) + 'px'
  
  return (
    <div className={classes.root}>
      { leftPaneContent &&
      <div className={classes.leftPane} style={leftPaneStyle}>
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <div className={classes.leftPaneContainer}
              style={{ 
                  width: width, 
                  height: containerHeight,
                  top: topInset,
                  position: 'static',
                  height: '100%',
                  top: 0,
                }}
              >
              { leftPaneContent }
            </div>
          )}
        />
      </div>          
      }

      <div className={classes.contentPane}>
      { fixedContentPane ?
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <div className={classes.contentPaneContainer}
              style={{ 
                  position: 'fixed',
                  overflow: 'scroll',
                  width: width, 
                  height: containerHeight,
                  top: topInset,
                  position: 'static',
                  width: 'auto',
                  height: '100%',
                  top: 0
                }}
              >
              { children }
            </div>
          )}
        />            
        :
        <div className={classes.contentPaneContainer}>
          { children }
        </div>
        }
      </div>

      { rightPaneContent &&
        <div className={classes.rightPane} style={rightPaneStyle}>
          <ResizeDetector
            handleWidth
            render={({ width }) => (
              <div className={classes.rightPaneContainer}
                style={{ 
                    width: width, 
                    height: containerHeight,
                    top: topInset,
                    width: 'auto',
                    position: 'static',
                    height: '100%',
                    top: 0                  
                  }}
                >
                { rightPaneContent }
              </div>
            )}
          />    
        </div>
      }
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(SplitView)  
