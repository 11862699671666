import React, { Component, useState, useEffect, useContext } from 'react'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SplitView from '../views/SplitView'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  root: {
    height: '100%',
  },
  listView: {
    width: '100%',
  },
  listItemView: {
    width: '100%'
  }
})

const useWindowSize = () => {
  const getWindowSize = () => ({ 
    width: window.innerWidth, 
    height: window.innerHeight 
    })
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const ListItemView = ({ theme, classes, itemPosition, getItem }) => {

  const item = getItem(itemPosition)

  return (
    <ListItem alignItems="flex-start" className={classes.listItemView}>
      <ListItemAvatar>
        { item.avatar }
      </ListItemAvatar>
      <ListItemText
        primary={ item.primaryText }
        secondary={ item.secondaryText }
      />
    </ListItem>
  )
}

const ListView = ({ theme, classes, listItemComponent=ListItemView, renderListItem, listItemDivider, itemCount, getItem }) => {

  const defaultListItemDivider = <Divider variant="" component="li" />

  const createListItem = (itemPosition) => {
    if (renderListItem)
      return renderListItem(itemPosition)
    else
      return React.createElement(listItemComponent, {
        theme, classes,
        itemPosition, getItem
      })
  }

  let listItems = null
  if (itemCount > 0) {
    listItems = Array(itemCount).fill().map((i,itemPosition) => 
        createListItem(itemPosition)
      )

    if (listItems.length > 0 && (listItemDivider || defaultListItemDivider))
      listItems = listItems.reduce((prev, next) => 
        [prev, listItemDivider || defaultListItemDivider, next]
        )    
  }

  return (
    <List className={classes.listView}>
      { itemCount > 0 ?
        <>
          { listItems }
        </>

      :
        <></>
      }      
    </List>
  )
}

const SplitListController = ({ theme, classes, renderList, listComponent=ListView, rightPaneContent, children, ...props }) => {

  let listContent = null
  if (renderList)
    listContent = renderList()
  else {
    listContent = React.createElement(listComponent, {
      theme, classes,
      ...props
      })
  }

  return (
    <div className={classes.root}>
      <SplitView
        leftPaneContent={listContent}
        rightPaneContent={rightPaneContent}
        {...props}
      >
        { children }
      </SplitView>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(SplitListController)  
